/* ----------  Imports  ---------- */

// React
import React, { useContext, useEffect, useState } from 'react';

// React Router DOM
import { useParams, Link as RouterLink } from 'react-router-dom';

// Lodash
import { isEmpty, join, map } from "lodash";

// React Bootstrap
import { Container, Modal, Col, Row, Card, Button, Nav, Item, Badge, Dropdown, Spinner, Form,InputGroup } from "react-bootstrap";

// React Scroll
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";

// Contexts
import { AuthContext } from '../../../context/auth';

// Profile Components
import EducationCard from "../../Individual/Components/profileComponent/EducationCard";
import WorkExperienceCard from "../../Individual/Components/profileComponent/WorkExperienceCard";
import { AccomplishmentCollapse } from "../../Individual/Components/profileComponent/AccomplishmentCollapse";
import { ProjectsCollapse } from "../../Individual/Components/profileComponent/ProjectsCollapse";

// API Services
import UserService from '../../../services/individual/user';
import InvitationService from '../../../services/common/invitation';

//Notification for error messages
import { notification } from 'antd';

/* ----------  User Profile  ---------- */

const UserProfile = (props) => {
	const { user } = useContext(AuthContext);
	// console.log({user});
	const inviteFormIntialValues = {
		type: '', // 2 -> Speaker | 3 -> Guest Lecture
		date: '', // dd/mm/yyy
		time: '', // hh:mm A
		description:''
	}

	const [showInviteModal, setShowInviteModal] = useState(false);
	const [showInviteSuccessModal, setShowInviteSuccessModal] = useState(false);
	const [busy, setBusy] = useState(false);
	const [inviting, setInviting] = useState(false);
	const [profile, setProfile] = useState({});
	const [inviteFormValues, setInviteFormValues] = useState(inviteFormIntialValues);
	const [isFromResponseManager, setIsFromResponseManager] = useState(false);

	const { userId } = useParams();

	const onInviteClick = e => {
		e.preventDefault();
		setShowInviteModal(true);
	}

	const onInviteSuccessModalHideClick = e => {
		e.preventDefault();
		setShowInviteSuccessModal(false);
	}

	const onInviteSubmit = e => {
		e.preventDefault();
		let errorMessages=[];
		let {type,date,time,description}=inviteFormValues;
		if(!type) errorMessages.push("Invite type is required");
		if(!date) errorMessages.push("Date is required");
		if(!time) errorMessages.push("Time is required");
		if(!description) errorMessages.push("Message is required");
		if(errorMessages.length > 0){
			notification.error({
				message: 'Validation Error',
				description: errorMessages.map((error,index) => <p className="m-0" key={index}>{error}</p>),
			});
		}
		else{
			inviteUser();
		}
	}

	const onInviteInputChange = e => {
		const { name, value } = e.currentTarget;

		setInviteFormValues({
			...inviteFormValues,
			[name]: value
		});
	}

	const getUserProfile = async () => {
		setBusy(true);

		try {
			const profileData = await UserService.GetProfile(userId);
			const connectionsData = await UserService.GetConnections(userId, {}, props.guard);
			const userProfile = { ...profileData.data.data, quickProfile: profileData.data.quick_profile, connections: connectionsData.data.data };
			setProfile(userProfile);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const getBaseRoute = () => {
		switch(props.guard) {
			case 'company': return 'corporate';
			default: return props.guard;
		}
	}

	const inviteUser = async () => {
		const formData = {
			userId: profile.id,
			userType: 1,
			type: inviteFormValues.type,
			dateTime: `${inviteFormValues.date} ${inviteFormValues.time}`,
			description:inviteFormValues.description
		}

		setInviting(true);

		try {
			const { data } = await InvitationService.RequestCampusInvitation(formData, user.guard || '');
			if (!data.success) return;
			setInviteFormValues(inviteFormIntialValues);
			setShowInviteModal(false);
			setShowInviteSuccessModal(true);
		} catch (error) {
			console.log(error);
		} finally {
			setInviting(false);
		}
	}

	const handleInviteModalHide = () => {
		setShowInviteModal(false);
	}

	const handleInviteSuccessModalHide = () => {
		setShowInviteSuccessModal(false);
	}

	const scrollToWithContainer = () => {
		let goToContainer = new Promise((resolve, reject) => {
			Events.scrollEvent.register("end", () => {
				resolve();
				Events.scrollEvent.remove("end");
			});

			scroller.scrollTo("scroll-container", {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart"
			});
		});

		goToContainer.then(() =>
			scroller.scrollTo("scroll-container-second-element", {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart",
				containerId: "scroll-container"
			})
		);
	}

	useEffect(() => {
		scrollToWithContainer();

		console.log('Loaded Profile!');
		getUserProfile();
		console.log({props});
		let urlParams = new URLSearchParams(window.location.search);
        let FromResponseManager = urlParams.get('responseManager');
		console.log({FromResponseManager});
		setIsFromResponseManager(FromResponseManager);

		Events.scrollEvent.register("begin", function () {
			console.log("begin", arguments);
		});

		Events.scrollEvent.register("end", function () {
			console.log("end", arguments);
		});

		return () => {
			Events.scrollEvent.remove("begin");
			Events.scrollEvent.remove("end");
		}
	}, []);

	if (isEmpty(profile) || busy) {
		return (
			<div className="p-5 text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
			</div>
		);
	}

	const renderConnections = () => {
		return map(profile.connections, connection => {
			const avatar = `${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${connection.profilePicture}`;

			return (
				<Col md={6} key={connection.id}>
					<Card className="connection-card">
						<Dropdown alignRight>
							<Dropdown.Toggle className="btn" id="dropdown-basic">
								<i className="fa fa-ellipsis-v"></i>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
								<Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
								<Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<RouterLink className="d-block" target="_blank" to={`/${getBaseRoute()}/profile/user/${connection.id}`}>
							<Row className="m-0 align-items-center">
								<img alt="pic" src={avatar} className="userPic" />
								<h2>
									{`${connection.firstName} ${connection.lastName}`}
									<label>{connection.designation || 'N/A'}</label>
									<small>{connection.city || 'N/A'}</small>
								</h2>
							</Row>
						</RouterLink>
					</Card>
				</Col>
			);
		});
	}

	const hasUserAddress = profile.locality || profile.city;

	return (
		<React.Fragment>
			<Container className="edit-profile">
				<Row>
					<Col md={4}>
						<Card className="profileCard profileCard-sticky-top mb-3">
							<p className="h-100 mb-3 text-center m-0">Profile Completion: { profile.profileCompleted || 0 }% <i className="fa fa-info ml-2"></i></p>
							<div className="userPic">
								<Circle />
								<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${profile.profilePicture}`} />
								<Button variant="light" className="d-none">
									<input type="file" name="profilePic" id="profilePic" />
									<i className="fa fa-camera"></i>
								</Button>
							</div>{/* pic */}
							<h3>
								{`${profile.firstName} ${profile.lastName}`}
								<small>{profile?.currentWork?.designation?.title || 'N/A'}</small>
								<small>{profile?.currentWork?.company?.title || 'N/A'}</small>
							</h3>
							{isFromResponseManager ? (
								<ul className="fa-ul">
									<li>
										<i className="fa fa-li fa-envelope"></i>
										{profile.email}
										{profile.isVerified ? <span className="float-right"><i className="fa fa-check text-center"></i></span> : ''}
									</li>
									<li><i className="fa fa-li fa-phone"></i>{profile.phone || 'N/A'}</li>
									<li>
										<i className="fa fa-li fa-map-marker"></i>
										{ profile.quickProfile.location || 'N/A' }
									</li>
								</ul>
							) : (
								<ul className="fa-ul">
									{profile.showEmail ? (
										<li>
											<i className="fa fa-li fa-envelope"></i>
											{profile.email}
											{profile.isVerified ? <span className="float-right"><i className="fa fa-check text-center"></i></span> : ''}
										</li>
									):('')}
									{profile.showPhone ? (
										<li><i className="fa fa-li fa-phone"></i>{profile.phone || 'N/A'}</li>
									):('')}
									<li>
										<i className="fa fa-li fa-map-marker"></i>
										{ profile.quickProfile.location || 'N/A' }
									</li>
								</ul>
							)}
							<div className="card-btns py-3">
								{ props.guard === 'user' && profile.connections.find((item)=>item.id===user.userDetails.id) ?
									<Button variant="light" className="btn-rounded p-4 border mr-2"><i className="fa fa-comment fa-lg text-black"></i></Button>
								: '' }
								{ props.guard === 'user' ?<Button variant="light" className="btn-rounded p-4 border mr-2"><i className="fas fa-calendar-alt fa-lg text-black"></i></Button>
								: '' }
								{ props.guard === 'institute' ? <Button variant="light" type="button" className="btn-rounded p-4 border mr-2" onClick={onInviteClick}><i className="fas fa-star fa-lg text-black"></i></Button> 
								: '' }
								{ props.guard === 'institute' || props.guard=== 'company' ?<Button variant="light" className="btn-rounded p-4 border mr-2"><i className="fa fa-file-download fa-lg text-black"></i></Button> 

								: '' }
								{/* <Button variant="light" className="btn-rounded-lg"><i className="fa fa-ellipsis-h"></i></Button> */}
							</div>{/* btns */}
							<div className="mutualPics d-none">
								<img alt="pic" src="/assets/user-1.png" />
								<img alt="pic" src="/assets/user-2.png" />
								<img alt="pic" src="/assets/user-3.png" />
								<img alt="pic" src="/assets/user-4.png" />
								<img alt="pic" src="/assets/user-5.png" />
								+ 20 mutual connections
							</div>{/* mutualPIcs */}
							<hr />
							<h5 className="mt-2">Summary</h5>
							<p className="h-100 m-0">
								{profile.summary || 'N/A'}
							</p>
						</Card>
					</Col>
					<Col m={8}>
						<Nav className="scrollNav" defaultActiveKey="work">
							<Nav.Item className="text-center">
								<Link to="work" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Work experience</Link>
							</Nav.Item>
							<Nav.Item className="text-center">
								<Link to="edu" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Education</Link>
							</Nav.Item>
							<Nav.Item className="text-center">
								<Link to="acc" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Accomplishment</Link>
							</Nav.Item>
							<Nav.Item className="text-center">
								<Link to="skill" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Skills</Link>
							</Nav.Item>
							<Nav.Item className="text-center">
								<Link to="con" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Connections</Link>
							</Nav.Item>
						</Nav>

						<Element name="work" className="element">
							<h3>Work experience</h3>
							{
								profile.work_experiences.length > 0 ?
									profile.work_experiences.map((item) => {
										return <WorkExperienceCard key={item.id} weItem={item} />;
									}) :
									<div className="no-data">
										You don't have any work experience yet!
								<br />
								Please add it
							</div>
							}
						</Element>

						<Element name="edu" className="element">
							<h3>Education</h3>
							{
								profile.educations.length > 0 ?
									/* education card Listing */
									profile.educations.map((item) => {
										return <EducationCard key={item.id} eduItem={item} />; 	
									}) :
									<div className="no-data">
										Add your education details.
									<br />
									Which will help to your connections for reference
								</div>
							}
						</Element>

						<Element name="acc" className="element">
							<h2>Accomplishment</h2>
							<div className="px-3">
								<ProjectsCollapse projects={profile.projects} />
								<AccomplishmentCollapse accomplishment={profile.accomplishments || []} />
							</div>
						</Element>

						<Element name="skill" className="element">
							<h3>Skills</h3>
							<div className="d-flex flex-row flex-wrap align-items-center">
								{map(profile.skills, skill => <Badge variant="secondary" className="mr-2 mb-2" key={skill.id}>{skill.title}</Badge>)}
							</div>
						</Element>

						<Element name="con" className="element">
							<h2 id="con">Connections</h2>
							<Row>
								{renderConnections()}
							</Row>
						</Element>
					</Col>
				</Row>
			</Container>
			<Modal show={showInviteModal} onHide={handleInviteModalHide} centered scrollable size="md" className="InviteModalModal">
				<form onSubmit={onInviteSubmit}>
					<Modal.Header closeButton>
						<Modal.Title>Invite</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group>
							<Form.Label>Invite As</Form.Label>
							<Form.Control as="select" value={inviteFormValues.type || 0} name="type" onChange={onInviteInputChange} custom>
								<option value="0" disabled>-- Select --</option>
								<option value="2">Speaker</option>
								<option value="3">Guest Lecturer</option>
							</Form.Control>
						</Form.Group>
						<Row className="mb-3 align-items-end">
						<Col>
							<Form.Group className="mb-0">
								<Form.Label> Message</Form.Label>
								<InputGroup>
									<Form.Control
										as="textarea" 
										name="description"
										placeholder=""
										style={{ "height": "100px", "resize": "none" }}
										minLength={1}
										maxLength={30}
										value={inviteFormValues.description}
										onChange={onInviteInputChange}>
									</Form.Control>
								</InputGroup>
								<p style={{ "textAlign": "end", "marginBottom": "-1rem" }}>
									{inviteFormValues.description.length ? inviteFormValues.description.length : 0}/{30}
								</p>
							</Form.Group>
						</Col>
					</Row>
						<Row>
							<Col>
								<Form.Group>
									<Form.Label>Date</Form.Label>
									<Form.Control name="date" type="date" value={inviteFormValues.date} onChange={onInviteInputChange}></Form.Control>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group>
									<Form.Label>Time</Form.Label>
									<Form.Control name="time" type="time" value={inviteFormValues.time} onChange={onInviteInputChange}></Form.Control>
								</Form.Group>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer className="justify-content-center">
						<Button variant="dark" type="submit" disabled={inviting}>{inviting ? 'Please Wait...' : 'Invite'}</Button>
					</Modal.Footer>
				</form>
			</Modal>
			<Modal show={showInviteSuccessModal} onHide={handleInviteSuccessModalHide} centered scrollable size="md" className="InviteModalModal">
				<Modal.Header closeButton>
					<Modal.Title>Invite</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Invitation sent successfully.</p>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button variant="dark" onClick={onInviteSuccessModalHideClick}>Close</Button>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	)
}

/* ----------  Circle  ---------- */

function Circle() {
	return (
		<>
			<svg
				className="progress-ring"
				width="125"
				height="125">
				<circle
					className="progress-ring__circle"
					stroke="orange"
					id="progressBar"
					strokeWidth="4"
					fill="transparent"
					r="54.5"
					cx="62.5"
					cy="62.5" />
			</svg>
		</>
	)
}

/* ----------  Props  ---------- */

UserProfile.defaultProps = {
	guard: 'institute'
}

/* ----------  Export  ---------- */

export default UserProfile;
