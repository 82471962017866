import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { AccomplishmentSave } from "./AccomplishmentSave";
import ProfileDataService from '../../../../services/individual/UserProfile';
import Autosuggest from "react-autosuggest";
/* acheivement modal */
export function Accomplishment(props) {
    const { accomplishmentProjectVal, onChangeAccomplishment, editSuggesions } = props.childAccomProjectToCall;

    const [show, setShow] = useState(false);
    const handleCloseAcc = () => setShow(false);
    const handleShowAcc = () => setShow(true);
    const [type, setType] = useState("");
    const [suggestions, setSuggestions] = useState([]);

    function getAccompleshmentTypes(val) {
        const input = {
            start: 0,
            length: 100,
            search: val
        }
        const result = ProfileDataService.fetchAccomplishmentType(input);
        return result;
    }

    return (
        <>
            <Button
                onClick={handleShowAcc}
                variant="white"
                className="btn float-right"
            >
                <i className="fa fa-plus fa-2x" style={{color: "#F16823"}}></i>
            </Button>

            <Modal className="edit-modals" show={show} onHide={handleCloseAcc} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Add Accomplishment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>
                            Type of Accomplishment<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            as="select"
                            custom
                            name="accmplishmentOptionType"
                            onChange={onChangeAccomplishment}
                            value={accomplishmentProjectVal.accmplishmentOptionType || "project"}

                        >
                            <option value="project">Project</option>
                            <option value="awards">Awards</option>
                            <option value="accomplishment">Achievement</option>
                            <option value="certification">Certification</option>
                        </Form.Control>
                    </Form.Group>
                    {/* {accomplishmentProjectVal.accmplishmentOptionType === "accomplishment" ?
                        <Form.Group>
                            <Form.Label>
                                Title<span className="text-danger">*</span>
                            </Form.Label>
                            <Autosuggest
                                inputProps={{
                                    placeholder: "Select type",
                                    autoComplete: "off",
                                    name: "type",
                                    id: "type",
                                    value: type,
                                    onChange: (e, { newValue }) => {
                                        setType(newValue)
                                    }
                                }}
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={
                                    async ({ value }) => {
                                        if (value === '') {
                                            setSuggestions([]);
                                            return;
                                        }
                                        try {
                                            const result = await getAccompleshmentTypes(value);
                                            setSuggestions(
                                                result.data.data.map((item) => ({
                                                    title: item.title,
                                                    id: item.id
                                                }))
                                            );
                                            console.log('This is ', result.data.data);
                                        } catch (e) {
                                            console.log(e)
                                            setSuggestions([]);
                                        }
                                    }
                                }

                                onSuggestionsClearRequested={() => { setSuggestions([]) }}
                                onSuggetionSelected={(event, { suggestion, method }) => {
                                    if (method === "enter")
                                        event.preventDefault();
                                }}
                                getSuggestionValue={(suggestion) => {
                                    editSuggesions(suggestion, props.index, "type");
                                    return suggestion.title
                                }}
                                renderSuggestion={suggestion => <div>{suggestion.title}</div>}
                            />
                        </Form.Group>
                        : ""
                    } */}

                    {accomplishmentProjectVal.accmplishmentOptionType === "project" ?
                        <div>
                            <Form.Group>
                                <Form.Label>
                                        Title<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    id="title"
                                    onChange={onChangeAccomplishment}
                                    value={accomplishmentProjectVal.title || ""}
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Project done at<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="projectDoneAt"
                                    id="projectDoneAt"
                                    placeholder="Work / Education / Personal"
                                    onChange={onChangeAccomplishment}
                                    value={accomplishmentProjectVal.projectDoneAt || ""}
                                ></Form.Control>
                            </Form.Group>

                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Start year<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="durationFrom"
                                            id="durationFrom"
                                            onChange={onChangeAccomplishment}
                                            value={accomplishmentProjectVal.durationFrom || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>End year</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="durationTo"
                                            id="durationTo"
                                            disabled={accomplishmentProjectVal.isOnGoing ? true : false}
                                            onChange={onChangeAccomplishment}
                                            value={accomplishmentProjectVal.durationTo || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        {["checkbox"].map((type) => (
                                            <div key={`custom-${type}`}>
                                                <Form.Check
                                                    custom
                                                    type={type}
                                                    id="isOnGoing"
                                                    name="isOnGoing"
                                                    label="No expiry"
                                                    onChange={onChangeAccomplishment}
                                                    checked={accomplishmentProjectVal.isOnGoing}
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>
                                </Col>
                            </Row>
                                <Form.Group>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        row="4"
                                        type="text"
                                        name="description"
                                        id="description"
                                        placeholder="Mention in short what the project is about."
                                        onChange={onChangeAccomplishment}
                                        value={accomplishmentProjectVal.description || ""}
                                    ></Form.Control>
                                </Form.Group>
                            <div className="text-center pt-3">
                                <AccomplishmentSave closeModal={handleCloseAcc} clickThis={props.chRef.addAccomplishment} />
                            </div>
                        </div> 
                        : accomplishmentProjectVal.accmplishmentOptionType === "awards" ?
                        <div>
                            <Form.Group>
                                <Form.Label>
                                    Title<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    id="title"
                                    onChange={onChangeAccomplishment}
                                    value={accomplishmentProjectVal.title || ""}
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Awarded By<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="projectDoneAt"
                                    id="projectDoneAt"
                                    onChange={onChangeAccomplishment}
                                    value={accomplishmentProjectVal.projectDoneAt || ""}
                                ></Form.Control>
                            </Form.Group>

                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Year<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="durationFrom"
                                            id="durationFrom"
                                            onChange={onChangeAccomplishment}
                                            value={accomplishmentProjectVal.durationFrom || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}></Col>
                            </Row>
                                <Form.Group>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        row="4"
                                        type="text"
                                        name="description"
                                        id="description"
                                        onChange={onChangeAccomplishment}
                                        value={accomplishmentProjectVal.description || ""}
                                    ></Form.Control>
                                </Form.Group>
                            <div className="text-center pt-3">
                                <AccomplishmentSave closeModal={handleCloseAcc} clickThis={props.chRef.addAccomplishment} />
                            </div>
                        </div> 
                        : accomplishmentProjectVal.accmplishmentOptionType === "accomplishment" ?
                        <div>
                            <Form.Group>
                                <Form.Label>
                                    Title<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    placeholder="Eg: Accomplished 100% sales target."
                                    type="text"
                                    name="title"
                                    id="title"
                                    onChange={onChangeAccomplishment}
                                    value={accomplishmentProjectVal.title || ""}
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Accomplished at<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    placeholder="Work / Education / Personal"
                                    type="text"
                                    name="projectDoneAt"
                                    id="projectDoneAt"
                                    onChange={onChangeAccomplishment}
                                    value={accomplishmentProjectVal.projectDoneAt || ""}
                                ></Form.Control>
                            </Form.Group>

                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Start year<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="durationFrom"
                                            id="durationFrom"
                                            onChange={onChangeAccomplishment}
                                            value={accomplishmentProjectVal.durationFrom || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>End year</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="durationTo"
                                            id="durationTo"
                                            disabled={accomplishmentProjectVal.isOnGoing ? true : false}
                                            onChange={onChangeAccomplishment}
                                            value={accomplishmentProjectVal.durationTo || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        {["checkbox"].map((type) => (
                                            <div key={`custom-${type}`}>
                                                <Form.Check
                                                    custom
                                                    type={type}
                                                    id="isOnGoing"
                                                    name="isOnGoing"
                                                    label="No expiry"
                                                    onChange={onChangeAccomplishment}
                                                    checked={accomplishmentProjectVal.isOnGoing}
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>
                                </Col>
                            </Row>
                                <Form.Group>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        placeholder="Achieved 100% sales target in 7 out of 8 quarters. Total sales of Rs.1.1 crore on a target of Rs.1 crore"
                                        as="textarea"
                                        row="4"
                                        type="text"
                                        name="description"
                                        id="description"
                                        onChange={onChangeAccomplishment}
                                        value={accomplishmentProjectVal.description || ""}
                                    ></Form.Control>
                                </Form.Group>
                            <div className="text-center pt-3">
                                <AccomplishmentSave closeModal={handleCloseAcc} clickThis={props.chRef.addAccomplishment} />
                            </div>
                        </div> 
                        :
                        <div>
                            <Form.Group>
                                <Form.Label>
                                    Title<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    placeholder="Eg: Accomplished 100% sales target."
                                    type="text"
                                    name="title"
                                    id="title"
                                    onChange={onChangeAccomplishment}
                                    value={accomplishmentProjectVal.title || ""}
                                ></Form.Control>
                            </Form.Group>
                            
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Certification by<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="title"
                                            id="title"
                                            onChange={onChangeAccomplishment}
                                            value={accomplishmentProjectVal.title || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>End year</Form.Label>
                                        <Form.Control
                                            placeholder="Full-time / Part-time"
                                            as="select"
                                            custom
                                            name="accmplishmentOptionType"
                                            onChange={onChangeAccomplishment}
                                            value={accomplishmentProjectVal.accmplishmentOptionType || "project"}

                                        >
                                            <option value="">Full Time</option>
                                            <option value="">Part Time</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Start year<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="durationFrom"
                                            id="durationFrom"
                                            onChange={onChangeAccomplishment}
                                            value={accomplishmentProjectVal.durationFrom || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>End year</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="durationTo"
                                            id="durationTo"
                                            disabled={accomplishmentProjectVal.isOnGoing ? true : false}
                                            onChange={onChangeAccomplishment}
                                            value={accomplishmentProjectVal.durationTo || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        {["checkbox"].map((type) => (
                                            <div key={`custom-${type}`}>
                                                <Form.Check
                                                    custom
                                                    type={type}
                                                    id="isOnGoing"
                                                    name="isOnGoing"
                                                    label="No expiry"
                                                    onChange={onChangeAccomplishment}
                                                    checked={accomplishmentProjectVal.isOnGoing}
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>
                                </Col>
                            </Row>
                                <Form.Group>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        placeholder="Mention in short what the project is about."
                                        as="textarea"
                                        row="4"
                                        type="text"
                                        name="description"
                                        id="description"
                                        onChange={onChangeAccomplishment}
                                        value={accomplishmentProjectVal.description || ""}
                                    ></Form.Control>
                                </Form.Group>
                            <div className="text-center pt-3">
                                <AccomplishmentSave closeModal={handleCloseAcc} clickThis={props.chRef.addAccomplishment} />
                            </div>
                        </div>
                    }

                </Modal.Body>
            </Modal>
        </>
    );
}
