import React, { Component, useEffect, useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Tabs, Tab, Container, Row, Col, Nav, Button, Form, InputGroup, Card, Dropdown, Modal, Spinner, Table } from 'react-bootstrap';
import Autosuggest from "react-autosuggest";
import { AuthContext } from '../../../../context/auth';
import IndividualConnectionDataService from '../../../../services/individual/IndividualConnections'
import { find, map } from 'lodash';
import { nanoid } from 'nanoid';
import GoogleContacts from 'react-google-contacts';
import { ConsoleWriter } from 'istanbul-lib-report';
import MutualConnections from '../../../Common/Components/connectionComponent/MutualConnections';
import Nouislider from "nouislider-react";

function ConnectionComponent(props) {
	const isProduction = process.env.REACT_APP_ENV === 'production';
	const [selectedTab, setSelectedTab] = useState("all");
	const [company, setCompany] = useState("");
	const [suggestions, setSuggestions] = useState([]);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [contacts, setContacts] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const inititalConnectionFilter={
		allConnection:{},
		recommanded:{},
		imported:{},
		pending:{
			requestType:'incoming',
		},
	};
	const [connectionFilterState,setConnectionFilterState]=useState(inititalConnectionFilter);

	var subtitle;
	const getCompanyList = val => {
		if (!val) return false;

		const input = {
			start: 0,
			length: 20,
			search: val,
			fields: 'id,title,profilePicture',
			'orderBy[column]': 'isVerified',
			'orderBy[dir]': 'desc',
			with: ['company']
		}

		const data = IndividualConnectionDataService.fetchCompanies(input);
		return data;
	}

	useEffect(() => {
		// getConnections();
		getContact()
	}, [selectedTab]);

	const getContact = async () => {
		// console.log("set contacts",setContacts)

		try {

			const data = await IndividualConnectionDataService.getImportedContacts();
			setContacts(data.data.data)
			// console.log("Contacts data",setContacts)
			// setContacts(data.data);
			// setContacts(data)
			// contacts = data.data.data
			console.log("Contacts data", contacts)
		} catch (error) {
			console.log(error);
		}
	}
	// setContacts({id:"1",name:"Tejas",email:"teanth98@gmail.com"})

	const renderContactsItems = () => {
		// contacts.push({id:"1",name:"Tejas",email:"teanth98@gmail.com"})
		return map(contacts, (item, i) => {
			return (
				// <tr key={item.id}>
				// 	<td><div className="tile">{i + 1}</div></td>
				// 	<td>
				// 		<div className="tile">{item.name}</div>

				// 	</td>
				// 	<td>
				// 		<div className="tile" >{item.email}</div>

				// 	</td>
				// 	<td>
				// 		<div className="tile" ><a href="#"><button onClick={e => invite(e, item.id)}>Invite</button></a></div>
				// 	</td>
				// </tr>
				<Row className="ind-contact-row" key={i}>
					<Col xs={4} className="d-flex">
						<Form.Check
							// custom
							type="checkbox"
						/>
						{/* <img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${item.profilePicture}`} /> */}
						{item.name}
					</Col>
					<Col xs={6} style={{wordWrap: "break-word"}}>{item.email}</Col>
					<Col xs={2}>
						<a href="#" onClick={e => invite(e, item.id)}>
							Invite
						</a>
					</Col>
				</Row>
			);

		});
	}

	const renderContacts = () => {
		return (
			// <p>hello</p>
			// 	console.log("item id",item.id)
			<React.Fragment>
					<Container className="px-0 px-lg-5">
						{/* <thead width="100%">
						<tr>
							<th width="5%"><div className="tile">Sr No.</div></th>
							<th><div className="tile">Name</div></th>
							<th width="50%"><div className="tile">Email</div></th>
							<th width="20%"><div className="tile">Invite</div></th>
						</tr>
							</thead> */}
								{/* { renderDesignationItems() } */}
								{/* <tr key={ item.id }>
							<td><div className="tile">{ i + 1 }</div></td>
							<td>
								<div className="tile">{ item.name }</div>

							</td>
							<td>
								<div className="tile" >{item.email}</div>

							</td>
							<td>
								<div className="tile" >Invite</div>
							</td>
						</tr> */}
						{renderContactsItems()}
					</Container>
			</React.Fragment>
		);
	}

	const handleTab = tab => {
		setSelectedTab(tab);
	}

	const responseCallback = (response) => {

		console.log(response);
		// if(response){

		// }
	}

	const invite = (e, id) => {
		// id_ = id
		e.preventDefault()
		const data = IndividualConnectionDataService.inviteEmail(id);
		// let req = {
		// 	id: id
		// };
		// var th=  this;
		// http().post('user/contact-import/contacts/invite', req)
		// .then(function(res){
		// 	if(res.error)
		// 		return window.alert(res.error);
		// 	window.alert('Invitation mail was successfully Sent!');
		// });
		// return false;
	}
	const responseCallbackSuccess = (response) => {
		console.log("Response is successful", response)
		// this.$http.post("user/contact-import/"+response)
		// .then(function(res){
		// 	// console.log(res);
		// 	if(res.error){
		// 		alert(res.error);
		// 		return false;
		// 	}
		// 	else{
		// 		console.log("Response for posting contacts in DB",response)
		// 	}
		// 	// window.open(res.redirectTo);
		// 	// window.location.href = res.redirectTo;
		// })
		// getContact();
	}
	const importContactsMicrosoft = async (e, provider) => {
		// var that = this;
		e.preventDefault()
		// let provider = "Microsoft"
		const data = await IndividualConnectionDataService.importContacts(provider);
		console.log("importing contacts", data);
		console.log("provider", provider)
		//  return http().post("user/contact-import/"+provider)
		// .then(function(res){
		// 	console.this.log(res);
		// 	if(res.error){
		// 		alert(res.error);
		// 		return false;
		// 	}
		// 	// window.open(res.redirectTo);
		// 	window.location.href = res.redirectTo;
		// })
	}

	const setConnectionFilter =(e) => {
		if(selectedTab==="all"){

		}
		else if(selectedTab==="recommended"){

		}
		else if(selectedTab==="pending"){
			setConnectionFilterState({
				...connectionFilterState,
				pending:{
					requestType:e.target.value,
				},
			});
		}
	}
	const applyConnectionFilter =async (type) => {
		let status=0;
		let requestType="";
		if(type==="PENDING"){
			requestType=connectionFilterState.pending.requestType;
			status=0;
		}
		else if(type==="ALL"){
			status=1;
		}
		const params = {
			status,
			start: 0,
			length: 100,
			search: '',
			type: 'all',
			withMutuals: true,
			fields: 'id,firstName,lastName,designation,companyName,profilePicture,isReciever',
			requestType:requestType
		}

		try {
			const { data } = await IndividualConnectionDataService.fetchIndividualAllConnections(params);
			setFilteredData(data.data);
		} catch (error) {
			setFilteredData([]);
		}
	}


	//   const importContacts = (provider) =>
	//   {
	// console.log("importing contacts");

	// this.$http.post("user/contact-import/"+provider)
	// .then(function(res){
	// 	// console.log(res);
	// 	if(res.error){
	// 		alert(res.error);
	// 		return false;
	// 	}
	// 	// window.open(res.redirectTo);
	// 	window.location.href = res.redirectTo;
	// })

	//   }


	return (
		<React.Fragment>

				<Tab.Container className="" id="left-tabs-example" defaultActiveKey="all" onSelect={(key) => handleTab(key)}>
					<div className="secondary-nav py-2 indConnection">
						<Container>
							<Row>
								<Col className="flex-column flex-lg-row">
									<Nav activeKey="/individual/connection" className="justify-content-start">
										<Nav.Item>
											<Nav.Link eventKey="all">All Connections</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="recommended">Recommended</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="imported">Imported</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="pending">Pending</Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
							{!isProduction ? <>
								<Col lg={3} className="my-2 my-lg-0 px-4">
									<InputGroup className="search-input">
										<Form.Control type="text" name="" id="" placeholder="Search"></Form.Control>
										<InputGroup.Append>
											<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup>
								</Col>
								<Col sm={12} lg={'auto'} className="d-flex justify-content-between px-4">
									<div className="d-flex mr-3">
									<Dropdown alignRight className="filter-Dropdown">
                                        <Dropdown.Toggle variant="" className="px-2 border-0 btn-bg-none" id="dropdown-basic">
                                            <i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i>
                                        </Dropdown.Toggle>
                                        {
											selectedTab == "all" ?
												<Dropdown.Menu>
														<div className="scroll-y d-flex flex-column">
															<label>Date <small>latest to oldest</small></label>
															<label>Bonus <small>high to low</small></label>
															<label>Degree <small>low to high</small></label>
														</div>
												</Dropdown.Menu>
											:
											''
										}
                                    </Dropdown>
                                    <Dropdown alignRight className="filter-Dropdown">
                                        <Dropdown.Toggle variant="" className="px-2 border-0 btn-bg-none" id="dropdown-basic">
                                            <i className="fa fa-filter"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="p-3">
											{
												selectedTab == "pending" ?
													<div className="scroll-y">
														<Form.Group>
															{['radio'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="received"
																		name="requestType"
																		value="incoming"
																		label="Received"
																		onChange={(e)=>setConnectionFilter(e)}
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="sent"
																		name="requestType"
																		label="Sent"
																		value="outgoing"
																		onChange={(e)=>setConnectionFilter(e)}
																	/>
																</div>
															))}
														</Form.Group>
													</div>
												:
													<div className="scroll-y">
														<label>Type</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="all"
																		name="all"
																		label="All"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="work"
																		name="work"
																		label="Work"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="education"
																		name="education"
																		label="Education"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Location</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

																</div>
															))}
														</Form.Group>
													</div>
											}
											<div className="text-center my-5 d-flex">
												<Button variant="dark-o">Cancel</Button>&nbsp;&nbsp;&nbsp;
												<Button variant="dark" onClick={()=>applyConnectionFilter("PENDING")}>Apply</Button>
											</div>
										</Dropdown.Menu>
									</Dropdown>
									</div>
									<div>
										<span className="importText">IMPORT</span>
										<Button className="mx-1" variant="secondary" onClick={e => importContactsMicrosoft(e, "GMAIL")}><i className="fab fa-google"></i></Button>
										<Button variant="secondary" onClick={e => importContactsMicrosoft(e, "MICROSOFT")}><i className="fab fa-microsoft"></i></Button>
									</div>
								</Col>
								</>: ('')}
							</Row>
						</Container>
					</div>
					<Container>
						<Tab.Content className="py-4">
							<Tab.Pane eventKey="all">
								<Row>
									{/* {renderContacts()} */}
								</Row>
								<Row>
									<ConnectionsBlock tab="all" filteredData={filteredData} selectedTab={selectedTab} />
								</Row>
							</Tab.Pane>{/* all */}
							<Tab.Pane eventKey="recommended">
								{/* <Row className="mb-3">
									<Col md={4}>
										<Autosuggest
											inputProps={{
												placeholder: "Type Company Name",
												autoComplete: "off",
												name: "company",
												id: "company",
												value: company,
												onChange: (e, { newValue }) => {
													setCompany(newValue)
												}
											}}
											suggestions={suggestions}
											onSuggestionsFetchRequested={
												async ({ value }) => {
													if (value === '') {
														setSuggestions([]);
														return;
													}

													try {
														const result = await getCompanyList(value);
														console.log('This is ', result.data.data);
														setSuggestions(
															result.data.data.map((item) => ({
																title: item.title,
																id: item.id
															}))
														);

													} catch (e) {
														console.log(e)
														setSuggestions([]);
													}
												}
											}

											onSuggestionsClearRequested={() => { setSuggestions([]) }}
											onSuggetionSelected={(event, { suggestion, method }) => {
												if (method === "enter")
													event.preventDefault();
											}}
											getSuggestionValue={(suggestion) => suggestion.title}
											renderSuggestion={suggestion => <div>{suggestion.title}</div>}
										/>
									</Col>
									<Col md={4}>
										<InputGroup>
											<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
											<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button></InputGroup.Append>
										</InputGroup>
									</Col>
									<Col md={4}>
										<Form.Control as="select">
											<option value="All">Work</option>
										</Form.Control>
									</Col>
								</Row> */}
								<Row>
									<ConnectionsBlock tab="recommended" filteredData={filteredData} selectedTab={selectedTab} />
								</Row>
							</Tab.Pane>{/* recomended */}
							<Tab.Pane eventKey="pending">
								{/* <Row className="mb-3">
									<Col md={8}>
										<InputGroup>
											<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
											<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button></InputGroup.Append>
										</InputGroup>
									</Col>
									<Col md={4}>
										{['radio'].map((type) => (
											<div key={`custom-${type}`} className="d-flex">
												<Form.Check
													custom
													type={type}
													id="sent"
													name="request_type"
													label="Sent"
													className="col-auto"
												/>
												<Form.Check
													custom
													type={type}
													id="recieved"
													name="request_type"
													label="Recieved"
													className="col-auto"
												/>
											</div>
										))}
									</Col>
								</Row> */}
								<Row>
									<ConnectionsBlock tab="pending" filteredData={filteredData} selectedTab={selectedTab} />
								</Row>
							</Tab.Pane>{/* pending */}
							<Tab.Pane eventKey="imported">
								{/* <Row className="mb-3">
									<Col md={8}>
										<InputGroup>
											<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
											<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
											</InputGroup.Append>
										</InputGroup>
									</Col>
									<Col md={4}>
										<Form.Control as="select">
											<option value="All">All</option>
										</Form.Control>
									</Col>
								</Row> */}
								<Row className="indConnection__importedWrapper">
									{renderContacts()}
								</Row>
								<Row className="d-flex justify-content-center">
									{/* <Col md={4}>
										<Card className="connection-card">
											<Button variant="secondary" className="btn-rounded btn-chat"><i className="fa fa-comment"></i></Button>
											<Dropdown alignRight>
												<Dropdown.Toggle className="btn" id="dropdown-basic">
													<i className="fa fa-ellipsis-v"></i>
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
													<Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
													<Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
											<Row className="m-0">
											</Row>
											<MutualConnections />
										</Card>
									</Col> */}
									<Button className="mx-1" variant="blue-gray">SELECT ALL</Button>
									<Button className="mx-1" variant="orange-gray">INVITE / CONNECT ALL</Button>
								</Row>
							</Tab.Pane>{/* all */}
						</Tab.Content>
					</Container>
				</Tab.Container>

		</React.Fragment>
	);
}

export default ConnectionComponent;

const ConnectionsBlock = (props) => {
	const { selectedTab,filteredData, tab } = props;
	// console.log({filteredData});
	const [busy, setBusy] = useState(true);
	const [userConnections, setUserConnections] = useState([]);

	const [reqBusy, setReqBusy] = useState(false);
	const [reqUserId, setReqUserId] = useState('');

	const onSendRequest = (e, userId) => {
		e.preventDefault();
		sendRequest(userId);
	}

	const onAcceptRequest = (e, userId) => {
		e.preventDefault();
		acceptRequest(userId);
	}

	const onRejectRequest = (e, userId) => {
		e.preventDefault();
		rejectRequest(userId);
	}

	const onCancelRequest = (e, userId) => {
		e.preventDefault();
		cancelRequest(userId);
	}

	const onUnfollowRequest = (e, userId) => {
		e.preventDefault();
		unfollowRequest(userId);
	}

	const onChat = (e, userId) => {
		e.preventDefault();
		console.log('Init Chat:', userId);
	}

	const getConnections = () => {
		switch (true) {
			case (tab === 'all') && (tab === selectedTab): getAllConnections(1); break;
			case (tab === 'pending') && (tab === selectedTab): getAllConnections(0); break;
			case (tab === 'recommended') && (tab === selectedTab): getRecommendedConnections(); break;
			default: break;
		}
	}

	const getAllConnections = async (status = 1) => {
		const params = {
			status,
			start: 0,
			length: 100,
			search: '',
			type: 'all',
			withMutuals: true,
			fields: 'id,firstName,lastName,designation,companyName,profilePicture,isReciever',
		}

		try {
			setBusy(true);
			const { data } = await IndividualConnectionDataService.fetchIndividualAllConnections(params);
			// const allConnections=data.data.filter((item) => item.mutuals.length > 0);
			setUserConnections(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const getRecommendedConnections = async () => {
		const params = {
			type: 'all',
			with: ['city', 'state'],
			start: 0,
			length: 100,
			search: '',
			withMutuals: true,
		}

		try {
			setBusy(true);
			const { data } = await IndividualConnectionDataService.fetchRecommendationsConnections(params);
			setUserConnections(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const sendRequest = async userId => {
		setReqBusy(true);
		setReqUserId(userId);

		try {
			const connections = [...userConnections];

			const { data } = await IndividualConnectionDataService.sendConnectionRequest(userId);
			if (data.success) {
				let newConnections=connections.filter((item)=>item.id!==userId);
				// const index = find(connections, { id: userId });
				// connections.splice(index, 1);
				setUserConnections(newConnections);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setReqBusy(false);
			setReqUserId('');
		}
	}

	const acceptRequest = async userId => {
		setReqBusy(true);
		setReqUserId(userId);

		try {
			const connections = [...userConnections];

			const { data } = await IndividualConnectionDataService.acceptConnectionRequest(userId);
			if (data.success) {
				let newConnections=connections.filter((item)=>item.id!==userId);
				// const index = find(connections, { id: userId });
				// connections.splice(index, 1);
				setUserConnections(newConnections);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setReqBusy(false);
			setReqUserId('');
		}
	}

	const rejectRequest = async userId => {
		setReqBusy(true);
		setReqUserId(userId);

		try {
			const connections = [...userConnections];

			const { data } = await IndividualConnectionDataService.rejectConnectionRequest(userId);
			if (data.success) {
				let newConnections=connections.filter((item)=>item.id!==userId);
				// const index = find(connections, { id: userId });
				// connections.splice(index, 1);
				setUserConnections(newConnections);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setReqBusy(false);
			setReqUserId('');
		}
	}

	const cancelRequest = async userId => {
		setReqBusy(true);
		setReqUserId(userId);

		try {
			const connections = [...userConnections];

			const { data } = await IndividualConnectionDataService.cancelConnectionRequest(userId);
			if (data.success) {
				let newConnections=connections.filter((item)=>item.id!==userId);
				// const index = find(connections, { id: userId });
				// connections.splice(index, 1);
				setUserConnections(newConnections);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setReqBusy(false);
			setReqUserId('');
		}
	}

	const unfollowRequest = async userId => {
		setReqBusy(true);
		setReqUserId(userId);

		try {
			const connections = [...userConnections];

			const { data } = await IndividualConnectionDataService.unfollowConnectionRequest(userId);
			if (data.success) {
				let newConnections=connections.filter((item)=>item.id!==userId);
				// const index = find(connections, { id: userId });
				// connections.splice(index, 1);
				setUserConnections(newConnections);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setReqBusy(false);
			setReqUserId('');
		}
	}

	useEffect(() => {
		getConnections();
	}, [selectedTab]);

	useEffect(() => {
		setUserConnections(filteredData);
	}, [filteredData]);

	if (busy) {
		return (
			<Col className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
			</Col>
		);
	}

	if (!busy && !userConnections.length) return <Col><p className="text-muted text-center">No connections found!</p></Col>;

	const renderActions = connection => {
		if (reqBusy && (reqUserId === connection.id)) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		const actions = [];

		if (tab === 'all') {
			actions.push(<Button variant="light" className="btn-rounded" onClick={e => onChat(e, connection.id)}><i className="fa fa-comment text-dark"></i></Button>);
		} else if (tab === 'recommended') {
			actions.push(<Button variant="light" className="btn-rounded" onClick={e => onSendRequest(e, connection.id)}><i className="fa fa-user-plus text-dark"></i></Button>);
		} else if (tab === 'pending') {
			if (connection.isReciever) {
				actions.push(<Button variant="light" className="btn-rounded" onClick={e => onAcceptRequest(e, connection.id)}><i className="fa fa-check text-dark"></i></Button>);
				actions.push(<i onClick={e => onRejectRequest(e, connection.id)} className="fa fa-times fa-lg text-dark" style={{color: "#fd7e14", cursor: "pointer"}}></i>);
			} else {
				actions.push(<i onClick={e => onCancelRequest(e, connection.id)} className="fa fa-times fa-lg text-dark" style={{color: "#fd7e14", cursor: "pointer"}}></i>);
			}
		}

		return (
			<React.Fragment>{map(actions, (action, i) => <span key={`connectionAction_${connection.id}_${i}`}>{action}</span>)}</React.Fragment>
		);
	}

	return map(userConnections, connection => {
		return (
			<Col md={6} xl={4} key={connection.id}>
				<Card className="connection-card">
					<Dropdown alignRight>
						<Dropdown.Toggle className="btn btn-bg-none" id="dropdown-basic" variant="orange-o">
							<i className="fa fa-ellipsis-v"></i>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{/* <Dropdown.Item className="d-flex flex-row align-items-center" href={`#/remove-${connection.id}`}><i className="fa fa-trash mr-2"></i> Remove</Dropdown.Item> */}
							<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
							<Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
							<Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
							{tab === 'all' && (
								<Dropdown.Item href="#" onClick={(e)=>onUnfollowRequest(e,connection.id)}>Remove <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
							)}
						</Dropdown.Menu>
					</Dropdown>
					<RouterLink className="d-block" target="_blank" to={`/individual/profile/user/${connection.id}`}>
						<Row className="m-0 align-items-center">
							<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${connection.profilePicture}`} className="userPic mr-2" />
							{/* <img alt="pic" src="https://via.placeholder.com/200" className="userPic mr-2" /> */}
							<h2>
								{connection.firstName} {connection.lastName}
								<label className="m-0">{connection.companyName ? (connection.companyName.length > 25 ? `${connection.companyName.slice(0,25)}...` : connection.companyName) : ''}</label>
								<small>{connection.designation}</small>
							</h2>
						</Row>
					</RouterLink>
					<Row className="align-items-end">
						<Col className="d-flex justify-content-center"><MutualConnections connections={connection.mutuals || []} />{/* mutualPIcs */}</Col>
						<Col xs={'auto'} className="border-left">
							{renderActions(connection)}
						</Col>
					</Row>
					{/* <label className="m-0 mt-2 text-muted">{connection.mutuals.length} mutual connection(s)</label> */}
					{/* <MutualConnections /> */}
				</Card>
			</Col>
		)
	});
}

export {
	ConnectionsBlock
};

/* invite modal */
function InviteModal() {
	const [show, setShow] = React.useState(false);

	const handleCloseInvite = () => setShow(false);
	const handleShowInvite = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowInvite} variant="secondary"><i className="far fa-user mr-1"></i>Invite</Button>

			<Modal show={show} onHide={handleCloseInvite} centered size="md" className="inviteModal">
				<Modal.Header closeButton>
					<Modal.Title>Enter email id to invite</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Control type="text" name="" id="" placeholder="You can enter multiple email with comma seperated"></Form.Control>
					</Form.Group>
					<div className="emailList">
						<div className="item d-flex">
							<span>abc@gmail.com</span><Button variant="white" className="btn-rounded"><i className="fa fa-times"></i></Button>
						</div>{/* item */}
						<div className="item d-flex">
							<span>abc@gmail.com</span><Button variant="white" className="btn-rounded"><i className="fa fa-times"></i></Button>
						</div>{/* item */}
					</div>{/* list */}
					<div className="text-center pt-3">
						<Button variant="dark">Send Invite</Button>
					</div>{/* center */}
				</Modal.Body>
			</Modal>
		</>
	);
}

// function ImportedContacts() {
// 	// const [contacts1, setContacts] = useState([]);

// 	const getContact = async () => {
// 	// console.log("set contacts",setContacts)

// 		try {

// 			// const  data  = await IndividualConnectionDataService.getImportedContacts();
// 			// setContacts(data.data.data)
// 			// console.log("Contacts data",setContacts)
// 			// setContacts(data.data);
// 			// setContacts(data)
// 			// contacts = data.data.data
// 			// console.log("Contacts data",contacts)
// 		} catch (error) {
// 			console.log(error);
// 		}
// 	}
// 	// const [show, setShow] = React.useState(false);
// 	// const  data  = IndividualConnectionDataService.getImportedContacts()
// 	// console.log("data from imported contacts",data)
// 	// getContact()
// 	// const handleCloseMutual = () => setShow(false);
// 	// const handleShowMutual = () => setShow(true);

// 	return map(abs, (item, i) => {
// 		// <p>hello</p>
// 	// 	console.log("item id",item.id)
// 		return (

// 			<Table>
// 					<thead>
// 						<tr>
// 							<th width="5%"><div className="tile">Sr No.</div></th>
// 							<th><div className="tile">Name</div></th>
// 							<th width="20%"><div className="tile">Email</div></th>
// 							<th width="10%"><div className="tile">Invite</div></th>
// 						</tr>
// 					</thead>
// 					<tbody>
// 						{/* { renderDesignationItems() } */}
// 						<tr key={ item.id }>
// 				<td><div className="tile">{ i + 1 }</div></td>
// 				<td>
// 					  <div className="tile">{ item.name }</div>

// 				</td>
// 				<td>
// 					<div className="tile" >{item.email}</div>

// 				</td>
// 				<td>
// 					<div className="tile" >Invite</div>
// 				</td>
// 			</tr>
// 					</tbody>
// 			</Table>

// 		);
// 	});
// }
