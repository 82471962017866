import React, { useState } from "react";
import { Row, Card } from "react-bootstrap";
import { EducationEditModal } from "./EducationEditModal";

const EducationCard = (props) => {
    const showPreview = props.showPreview === undefined ? true : props.showPreview;
    const { course,  isOnGoing, institute, duration_from_month, duration_from_year, duration_to_month, duration_to_year,specialization } = props.eduItem;
    // const {specialization} =props.eduItem.course
    // console.log(specialization);
    const fromDate = new Date(`${duration_from_year}-${duration_from_month}`);
    const fromMonth = fromDate.toLocaleString('default', { month: 'long' })
    let endDate = "";
    if (duration_to_year !== null && isOnGoing === false) {
        const toDate = new Date(`${duration_to_year}-${duration_to_month}`);     
        const toMonth = toDate.toLocaleString('default', { month: 'long' });
        endDate = toMonth + " " + duration_to_year;
    } else {
        endDate = "Present";
    }

    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onDeleteItem = e => {
        e.preventDefault();
        if (props.removeEducation) props.removeEducation(props.index);
    }

    return (
        <Card className="profile-data-card education-card">
            {showPreview === false ?
                <>
                    <div className="actions d-flex flex-row align-center justify-content-end">
                        <span className="mr-3"><i className="fa fa-pencil-alt" role="button" onClick={handleShow}></i></span>
                        <span><i className="fa fa-trash" role="button" onClick={onDeleteItem}></i></span>
                    </div>
                    <EducationEditModal index={props.index} handleClose={handleClose} show={show} eduItem={props.eduItem} />
                </>
                :
                ""
            }
            <Row className="m-0 flex-md-nowrap">
                <i className="fa fa-graduation-cap fa-5x"></i>
                <h2 className="text-wrap">
                    <label className="text-truncate">{props.eduItem.course ? props.eduItem.course.title : "Specialization"} <br />
                        {`${institute ? institute.title ? institute.title : '' : 'Institute'}, Location`}</label>
                    <small className="font-weight-bold">{specialization?.title}</small>
                    <small className="text-wrap">{`${fromMonth} ${duration_from_year} - ${endDate}`}</small>
                </h2>
            </Row>
        </Card>
    )
}

export default EducationCard;
