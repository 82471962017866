import React, { useState } from 'react';
import {Row, Col, Button, Card, Modal } from 'react-bootstrap';
import { map } from 'lodash';

/* tracking modal */
function TrackModal(props) {
	const { data, reverse } = props;
	const [show, setShow] = useState(false);

	const handleCloseApply = () => setShow(false);
	const handleShowApply = () => setShow(true);

	const renderDivider = () => (
		<div className="item arrow">
			<img src="/assets/long-arrow-right.svg" alt="" />
		</div>
	)

	if (!data) return '';

	const renderUsers = () => {
		let chain = [...data]
		if (reverse == true) {
			chain.reverse()
		}
		return map(chain, (item, i) => {
			const avatar = `${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${item.user.profilePicture}`;
			const hasDivider = (i > 0) && (i < chain.length);

			return (
				<React.Fragment key={`trackUser_${item.id}`}>
					{ hasDivider ? renderDivider() : ''}
					<div className="item">
						{item.status == 1 ? <i className="fa fa-check"></i> : ''}
						<img alt="pic" src={avatar} />
						<label>{item.user.firstName} {item.user.lastName}</label>
						<p className="font-italic text-muted" style={{ fontSize: '10px' }}>{item.acceptedRejectedAt}</p> 
						{/* <p className="font-italic text-muted" style={{ fontSize: '10px' }}>20 Jan 2020,<br />
						10:20 AM</p> */}
						
					</div>
				</React.Fragment>
			);
		});
	}

	return (
		<>
			<Button onClick={handleShowApply} className={`btn btn-rounded btn-track ${props.btnClass || ''}`} variant="">
				<div className="no">
					{props.degree || data.length}
					<svg width="30" height="30" viewBox="0 0 30 30">
						<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="10" strokeDashoffset="4" />
					</svg>
				</div>
			</Button>

			<Modal show={show} onHide={handleCloseApply} centered size="lg" className="apply-modal">
				<Modal.Header closeButton>
					<Modal.Title>{props.modalTitle}</Modal.Title>
                    {props.showBonus ?
                        <Col className="text-right"><i className="fa fa-money-bill-alt mr-1 mt-1 text-orange"></i>₹ {props.bonusAmount}/-</Col>
                        : ''
                    }
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							<p>
								{props.modalSubTitle || 'Track:'}
							</p>
						</Col>
					</Row>
					<Card className="p-0 border-0">
						<div className="refered-list">
							{renderUsers()}

						</div>
					</Card>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button onClick={handleCloseApply} variant="dark">OK</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default TrackModal;