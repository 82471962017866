/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Tag Autocomplete
import ReactTagAutocomplete from "react-tag-autocomplete";

/* ----------  React Tags  ---------- */

const ReactTags = (props) => {
	return (
		<ReactTagAutocomplete
			allowBackspace={ false }
			classNames ={{
				root: 'react-tags',
				rootFocused: 'is-focused',
				selected: 'react-tags__selected',
				selectedTag: 'react-tags__selected-tag',
				selectedTagName: 'react-tags__selected-tag-name',
				search: 'react-tags__search',
				searchWrapper: `react-tags__search-wrapper ${ props.hasSearchIcon ? 'has-search-icon' : '' }`.trim(),
				searchInput: 'react-tags__search-input form-control',
				suggestions: 'react-tags__suggestions',
				suggestionActive: 'is-active',
				suggestionDisabled: 'is-disabled',
				suggestionPrefix: 'react-tags__suggestion-prefix'
			}}
			{ ...props }
		/>
	);
}

/* ----------  Exports  ---------- */

export default ReactTags;
