import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, NavLink } from 'react-router-dom';
import NotFound from '../../Common/Components/NotFound';
import Layout from '../../Common/Components/Layout';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import EditProfile from './profileComponent/edit-profile';
import Profile from './profileComponent/profile';
import Recomment from './recommendedComponent/recomment';
import IndividualHomeComponent from './homeComoponent/home';
import ConnectionComponent from './connectionComponent/connection';
import SearchComponent from './searchComponent/search';
import ExploreComponent from './exploreComponent/explore';
import ReferralComponent from './referralComponent/referral';
import VoucherComponent from './voucherComponent/voucher';
import VoucherCartComponent from './voucherComponent/voucher-cart';
import WalletComponent from './walletComponent/wallet';
import NewPostComponent from './postComponent/newPostComponent/new-post';
import PostDetailsComponent from '../../Common/Components/postComponent/post-details';
import SettingsComponent from './settingsComponent/settings';
import instituteProfileDetailsComponent from '../../Common/Components/instituteProfileComponent/profile-details';
import CompanyProfileDetails from '../../Common/Components/companyProfileDetails/profile-details';
import { AuthContext } from '../../../context/auth';
import Notifications from '../../Common/Components/Notifications';
import ChatComponent from '../../Common/Components/chatComponent';
import UserPublicProfile from '../../public/profileComponent/UserProfile';

class Individual extends Component {
	static contextType = AuthContext;

	constructor(props) {
		super(props);

		this.state = {
			active: false,
			chat: false,
			chatSize: false,
			chatWindow: false,
			chatWindowMin: true,
			text: '',
			post: {
				postId: null
			},
			userData: {
				avatar: "",
				firstName: "",
				id: "",
				isOnline: "",
				lastName: "",
				name: "User",
				type: "",
				type_code: ""
			},

			unreadChatCount: 0
		}
	}

	handleOnEnter(text) {
		console.log("enter", text);
	}

	UNSAFE_componentWillReceiveProps() {
		if(!this.state.userData.id) {
			const { user } = this.context;

			if(user && user.userDetails) {
				if (user.guard !== "user") {
					this.props.history.push("/");
					return;
				}

				this.setState({ userData: user.userDetails }, () => {
					console.log('setting user data');
				});
			}
		}
	}

	componentDidMount() {
		if(!localStorage.getItem('refToken') || localStorage.getItem('refUserType') !== "user") {
			this.props.history.push("/");
		}
	}

	openPostSidebar = (e, postId) => {
		e.preventDefault();

		this.setState({ active: true });
		if(postId) this.setState({ post: { ...this.state.post, postId } });
	}

	closePostSidebar = () => {
		this.setState({ active: false });
		this.setState({ post: { ...this.state.post, postId: null } });
	}

	setUnreadCount = (count = 0) => {
		this.setState({ unreadChatCount: count });
	}

	render() {
		return (
			<React.Fragment>
				<Router>
					<Layout>
						<Navbar collapseOnSelect expand="lg" variant="dark" sticky="top" className="navBar">
							<Link className="navbar-brand navBrand" to="/individual">REFEROUTE</Link>
							<Navbar.Toggle aria-controls="responsive-navbar-nav" />
							<Navbar.Collapse id="responsive-navbar-nav">
								<Nav className="mr-auto">
									<NavLink className="nav-link" to="/individual/home/wall">Home</NavLink>
									<NavLink className="nav-link" to="/individual/explore">Explore<sup className="nav-notification"><i className="fa fa-circle"></i></sup></NavLink>
									<NavLink className="nav-link" to="/individual/referral">Referrals</NavLink>
									<NavLink className="nav-link" to="/individual/connection">Connections</NavLink>
								</Nav>
								<Nav className="ml-auto navbar-nav-user">
									<Link className="nav-link" to="#"><Button onClick={ this.openPostSidebar } variant="white" className="navPostBtn">NEW POST</Button></Link>
									{process.env.REACT_APP_ENV !== 'production' ? 
										<Link className="nav-link" to="/individual/search">
											{/* <input type="search" className="form-control header-searh" name="" id=""  placeholder="Use ; to add multiple keywords" /> */}
											<i className="fa fa-search navIcons"></i>
										</Link>
									 : ('')}
									<Link className="nav-link" to="#"><Button onClick={() => this.setState({chat:!this.state.chat})} variant="nav" className="btn-nav-chat btn-auto"><i className="fa fa-comment-alt navIcons"></i><sup className="nav-notification-count">{ this.state.unreadChatCount }</sup></Button></Link>
									<Notifications guard="user"/>
									<NavDropdown id="collasible-nav-dropdown" className="nav-user-dropdown" title={
											<div className="pull-left">
												<img alt="pic" src={ this.state.userData.avatar ? `${ process.env.REACT_APP_API_PUBLIC_URL }${ this.state.userData.avatar }` : '/assets/default-user-ic.png' }/>
												{this.state.userData.firstName.substring(0, 10)}
											</div>
										}>
										<NavLink className="dropdown-item" to="/individual/profile">Profile<i className="fa ml-2 fa-user float-right navIcons"></i></NavLink>
										<NavLink className="dropdown-item" to="/individual/wallet">Wallet<i className="fa ml-2 fa-folder float-right navIcons"></i></NavLink>
										<NavLink className="dropdown-item" to="/individual/voucher">Vouchers<i className="fa ml-2 fa-tag float-right navIcons"></i></NavLink>
										<NavLink className="dropdown-item" to="/individual/settings">Settings<i className="fa ml-2 fa-cog float-right navIcons"></i></NavLink>
										<NavLink className="dropdown-item" to="/logout" onClick={(e) => {
											e.preventDefault();
											this.context.logout();
											this.props.history.push("/");
										}}>Logout<i className="fa ml-2 fa-sign-in-alt float-right"></i></NavLink>
									</NavDropdown>
								</Nav>
							</Navbar.Collapse>
						</Navbar>
						<main className="mainContainer">
							<Switch>
								<Route exact path="/individual" component={ IndividualHomeComponent } />
								<Route path="/individual/edit-profile" component={ EditProfile } />
								<Route path="/individual/profile" exact component={ Profile } />
								<Route path="/individual/recommended" component={ Recomment } />
								<Route path="/individual/home">
									<IndividualHomeComponent openPostSidebar={ this.openPostSidebar } />
								</Route>
								<Route path="/individual/connection" component={ ConnectionComponent } />
								<Route path="/individual/search" component={ SearchComponent } />
								<Route path="/individual/explore" component={ ExploreComponent } />
								<Route path="/individual/referral" component={ ReferralComponent } />
								<Route path="/individual/voucher" component={ VoucherComponent } />
								<Route path="/individual/voucher-cart" component={ VoucherCartComponent } />
								<Route path="/individual/wallet" component={ WalletComponent } />
								<Route path="/individual/post/details/:id" component={ PostDetailsComponent } />
								<Route path="/individual/settings" component={ SettingsComponent } />
								<Route path="/individual/institute-profile/:instituteId" component={ instituteProfileDetailsComponent } />
								<Route path="/individual/company-profile/:companyId" component={ CompanyProfileDetails } />
								<Route path="/individual/profile/user/:userId">
									<UserPublicProfile guard="user"/>
								</Route>
								<Route component={ NotFound } />
							</Switch>
						</main>
					</Layout>
					<div className={ this.state.active ? "rightsheet active" : "rightsheet" }>
						<div className="sheetBody">
							<div className="close" onClick={ this.closePostSidebar }>
								<i className="fas fa-times"></i>
							</div>
							<h2 className="sheet-tittle">New Post</h2>
							<NewPostComponent active={ this.state.active } close={ this.closePostSidebar } postId={ this.state.post.postId }/>
						</div>
					</div>
					<ChatComponent active={ this.state.chat } setUnreadCount={ this.setUnreadCount } guard="user"/>
				</Router>
			</React.Fragment>
		);
	}
}

export default Individual;
