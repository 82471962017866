import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import WorkExpereienceSave from "./WorkExpereienceSave";
import { WorkExperienceContext } from "./WorkExperienceContext";
import ProfileDataService from '../../../../services/individual/UserProfile';
import Autosuggest from "react-autosuggest";
import PlacesAutocomplete from 'react-places-autocomplete';
import { placeGoogle } from "./googleAddrCall";

/* work expreince modal */
export default function WorkExpereienceEditModal(props) {
    const { company, address, currentCTC, designation, isOnGoing, industry, is_current, job_type, location, locality, city, state, country, durationFrom, durationTo, duration_from_month, duration_from_year, duration_to_month, duration_to_year, description,email,cts,email_verification_status,verification_email_sent,emailVerified } = props.weItem;
    // console.log(props.weItem);
    //company search
    const fullAddress = `${locality ? locality + ", " : ""} ${city.name}, ${state.name}, ${country.name}`
    const [companyName, setcompanyName] = useState(company?.title || '');
    const [suggestions, setSuggestions] = useState([]);
    const [weDesignation, setweDesignation] = useState(designation.title);
    const [addressLoc, setAddressLoc] = useState(fullAddress);
    const [weIndustry, setweIndustry] = useState(industry?.title ?? '');
    let roundCurrentCTC=Math.round(currentCTC);
    let roundCTS=Math.round(cts);
    function getCompanyList(val) {
        if (!val) return false;
        const input = {
            start: 0,
            length: 100,
            search: val,
            fields: 'id,title,profilePicture',
            // 'orderBy[column]': 'isVerified',
            // 'orderBy[dir]': 'desc',
            with: ['company']
        }
        const data = ProfileDataService.fetchCompanyData(input);
        return data;
    }

    function getDesignationList(val) {
        if (!val) return false;
        const input = {
            start: 0,
            length: 100,
            search: val,
            fields: 'id,title',
            // 'orderBy[column]': 'isVerified',
            // 'orderBy[dir]': 'desc',
            with: ['search']
        }
        const data = ProfileDataService.fetchDesignations(input);
        return data;
    }


    const getWEmodalData= {...props.weItem,companyName,weDesignation,addressLoc,weIndustry,cts:roundCurrentCTC ?? roundCTS ?? 0};
    // console.log({getWEmodalData});
    // console.log("suggestions::", suggestions);
    // console.log("addressLoc::", addressLoc);

    return (
        <WorkExperienceContext.Consumer>
            {({ onEditWEHandler, editSuggesions, industries, workExperienceNewState }) => (

                <Modal className="edit-modals" show={props.show} onHide={props.handleClose} centered size="md" backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Work Experience</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>
                                Company Name <span className="text-danger">*</span>
                            </Form.Label>
                            <Autosuggest
                                inputProps={{
                                    placeholder: "Type Company Name",
                                    autoComplete: "off",
                                    name: "company",
                                    id: "company",
                                    value: companyName,
                                    onChange: (e, { newValue }) => {
                                        setcompanyName(newValue)
                                    }
                                }}
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={
                                    async ({ value }) => {
                                        if (value === '') {
                                            setSuggestions([]);
                                            return;
                                        }
                                        try {
                                            const result = await getCompanyList(value)
                                            setSuggestions(
                                                result.data.data.map((item) => ({
                                                    title: item.title,
                                                    id: item.id
                                                }))
                                            );
                                            console.log('This is ', result.data.data);
                                        } catch (e) {
                                            console.log(e)
                                            setSuggestions([]);
                                        }
                                    }
                                }

                                onSuggestionsClearRequested={() => { setSuggestions([]) }}
                                onSuggetionSelected={(event, { suggestion, method }) => {
                                    if (method === "enter")
                                        event.preventDefault();
                                }}
                                getSuggestionValue={(suggestion) => {
                                    editSuggesions(suggestion, props.index, "company");
                                    return suggestion.title
                                }}
                                renderSuggestion={suggestion => <div>{suggestion.title}</div>}
                            />
                        </Form.Group>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>
                                        Designation<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Autosuggest
                                        inputProps={{
                                            placeholder: "Designation",
                                            autoComplete: "off",
                                            name: "designation",
                                            id: "designation",
                                            value: weDesignation,
                                            onChange: (e, { newValue }) => {
                                                setweDesignation(newValue)
                                            }
                                        }}
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={
                                            async ({ value }) => {
                                                if (value === '') {
                                                    setSuggestions([]);
                                                    return;
                                                }
                                                try {
                                                    const result = await getDesignationList(value);
                                                    console.log('This is ', result.data.data);
                                                    setSuggestions(
                                                        result.data.data.map((item) => ({
                                                            title: item.title,
                                                            id: item.id
                                                        }))
                                                    );
                                                } catch (e) {
                                                    console.log(e)
                                                    setSuggestions([]);
                                                }
                                            }
                                        }

                                        onSuggestionsClearRequested={() => { setSuggestions([]) }}
                                        onSuggetionSelected={(event, { suggestion, method }) => {
                                            if (method === "enter")
                                                event.preventDefault();
                                        }}
                                        getSuggestionValue={(suggestion) => {
                                            editSuggesions(suggestion, props.index, "designation");
                                            return suggestion.title
                                        }}
                                        renderSuggestion={suggestion => <div>{suggestion.title}</div>}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>
                                        Location<span className="text-danger">*</span>
                                    </Form.Label>
                                    <PlacesAutocomplete
                                        autocomplete="off"
                                        value={addressLoc ? addressLoc : fullAddress}
                                        onChange={value => setAddressLoc(value)}
                                        onSelect={value => editSuggesions(value, props.index, "location")}
                                    >
                                        {placeGoogle}
                                    </PlacesAutocomplete>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>
                                        Industry<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Autosuggest
                                        inputProps={{
                                            placeholder: "Industry",
                                            autoComplete: "off",
                                            name: "industry",
                                            id: "industry",
                                            value: weIndustry,
                                            onChange: (e, { newValue }) => {
                                                setweIndustry(newValue)
                                            }
                                        }}
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={
                                            async ({ value }) => {
                                                if (value === '') {
                                                    setSuggestions([]);
                                                    return;
                                                }
                                                try {
                                                    const result = industries ? industries : [];
                                                    const inputValue = value.trim().toLowerCase();
                                                    const inputLength = inputValue.length;
                                                    const filterIndustry = inputLength === 0 ? [] : result.filter(res =>
                                                        res.title.toLowerCase().slice(0, inputLength) === inputValue
                                                    );
                                                    // console.log("filterIndustry::", filterIndustry)
                                                    setSuggestions(filterIndustry);
                                                } catch (e) {
                                                    console.log(e)
                                                    setSuggestions([]);
                                                }
                                            }
                                        }

                                        onSuggestionsClearRequested={() => { setSuggestions([]) }}
                                        onSuggetionSelected={(event, { suggestion, method }) => {
                                            if (method === "enter")
                                                event.preventDefault();
                                        }}
                                        getSuggestionValue={(suggestion) => {
                                            editSuggesions(suggestion, props.index, "industry");
                                            return suggestion.title
                                        }}
                                        renderSuggestion={suggestion => <div>{suggestion.title}</div>}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>
                                        Job type<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        custom
                                        name="job_type"
                                        id="job_type"
                                        onChange={event => onEditWEHandler(event, props.index)}
                                        value={job_type || "1"}
                                    // onChange={props.childWEToCall.onEditWEHandler}
                                    // value={job_type || "Full time"}
                                    >
                                        <option value="1">Full time</option>
                                        <option value="2">Part time</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>
                                        CTC / Annual Income<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="currentCTC"
                                        id="currentCTC"
                                        onChange={event => onEditWEHandler(event, props.index)}
                                        value={roundCurrentCTC ?? roundCTS ?? ""}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-0 mt-3">
                                    {["checkbox"].map((type) => (
                                        <div key={`custom-${type}`}>
                                            <Form.Check
                                                custom
                                                type={type}
                                                id="ctc_visible"
                                                name="ctc_visible"
                                                label="Hide CTC/Annual Income"
                                                onChange={event => onEditWEHandler(event, props.index)}
                                            // onChange={props.childWEToCall.onEditWEHandler}
                                            // checked={false}
                                            />
                                        </div>
                                    ))}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>
                                        Start date<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="durationFrom"
                                        id="durationFrom"
                                        onChange={event => onEditWEHandler(event, props.index)}
                                        value={durationFrom || ""}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>End date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="durationTo"
                                        id="durationTo"
                                        disabled={isOnGoing ? true : false}
                                        onChange={event => onEditWEHandler(event, props.index)}
                                        value={durationTo || ""}
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    {["checkbox"].map((type) => (
                                        <div key={`custom-${type}`}>
                                            <Form.Check
                                                custom
                                                type={type}
                                                id="isOnGoing"
                                                name="isOnGoing"
                                                label="I currently work here"
                                                onChange={event => onEditWEHandler(event, props.index)}
                                                checked={isOnGoing || false}
                                            />
                                        </div>
                                    ))}
                                </Form.Group>
                            </Col>
                        </Row>
                        {isOnGoing && (
                            <Row className="d-flex align-items-end">
                                <Col md={verification_email_sent===true && emailVerified===1 ? 12 :8}>
                                    <Form.Group controlId="email">
                                        <Form.Label>Work Email ID</Form.Label>
                                        <Form.Control type="email" name="email" value={email || ""} onChange={event => onEditWEHandler(event, props.index)}></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    {
                                        (verification_email_sent===false && emailVerified===0) ?
                                        (
                                        <Button className="btn-verify mb-3" name="email_verification_status" type="button" variant="outline-success" onClick={event => onEditWEHandler(event, props.index)}>
                                            VERIFY
                                        </Button>
                                        ) :
                                        ""
                                    }
                                </Col>
                            </Row>
                        )}
                        <Form.Group>
                            <Form.Label>
                                Description<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                type="text"
                                name="description"
                                id="description"
                                onChange={event => onEditWEHandler(event, props.index)}
                                value={description || ""}
                            ></Form.Control>
                        </Form.Group>
                        <div className="text-center pt-3">
                            {/* <WorkExpereienceSave bottunText={"Update"} clickThis={props.chRef.updateWorkExperiece} /> */}
                            <WorkExpereienceSave bottunText={"Update"} onCloseEdit={props.handleClose} WEmodalData={getWEmodalData} />
                        </div>

                    </Modal.Body>
                </Modal>
            )}
        </WorkExperienceContext.Consumer>
    );
}
