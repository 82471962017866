import React, { useState, useContext, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Accordion, Button, InputGroup } from "react-bootstrap";
// import { useHistory } from "react-router";
import PlacesAutocomplete from 'react-places-autocomplete';
import { googleAddrCall, placeGoogle } from "../../../Common/Address/googleAddrCall";
import { AuthContext } from "../../../../context/auth";
import ProfileDataService from '../../../../services/individual/UserProfile';
import getLocation from '../../../../services/common/location';
import Profile from "./profile";
import WorkExperienceCard from "./WorkExperienceCard";
import WorkExperienceModal from "./WorkExperienceModal";
import PhoneVerificationModal from "./PhoneVerificationModal";
import WorkEmailVerificationModal from "./WorkEmailVerificationModal";
import { Education } from "./Education";
import EducationCard from "./EducationCard";
import { Accomplishment } from "./Accomplishment";
import { AccomplishmentCollapse } from "./AccomplishmentCollapse";
import { ProjectsCollapse } from "./ProjectsCollapse";
import { WorkExperienceContext } from "./WorkExperienceContext";
import { EducationContext } from "./EducationContext";
import { AccomplishmentContext } from "./AccomplishmentContext";
import { serialize } from 'object-to-formdata';
import { filter, omit } from "lodash";
import { notification } from 'antd';
import Notify from '../../../../Helpers/notify';
import ReactTags from "../../../Common/Components/ReactTags";


export default function EditProfile(props) {
	/**
	 * Context API to get the current user from
	 */
	// const { user, logout } = useContext(AuthContext);
	// const userDetails = user.userDetails || {};
	//   console.log(userDetails);

	//Initial Object of values for update/show profile with default values
	const initialValues = {
		userProfilePicture: null,
		full_name: null,
		name: null,
		gender: null,
		email: null,
		phone: null,
		countryPhone: null,
		countryCode:null,
		locality: null,
		pincode: null,
		city: null,
		state: null,
		country: null,
		hideEmailAndPhone: "",
		emailVerified: "",
		phoneVerified: "",
		currDesignation: null,
		curr_company: null,
		resume: null,
		status: null,
		is_open_for_job: true,
		show_profile_in_ref: false,
		showEmail: 1,
		showPhone: 1,
		profile: {
			summary: null,
			skills: null,
			accomplishment: [],
			projects: [],
			education: [],
			work_experience: []
		},
		preferreds: [],
		address: ""
	};

	//Set the state for user oject
	const [editValues, setEditValues] = useState(initialValues);
	const [showPreview, setShowPrevew] = useState(false);
	const [showPhoneVerification, setShowPhoneVerification] = useState(false);
	const [industries, setindustries] = useState([]);
	const [countryCodes, setCountryCodes] = useState([]);

	// profile multiple values for temporary operation to save into the DB
	// Need to change this for better optimization, need to save the data when popup is closed
	// const [editTempWEValues, setEditTempWEValues] = useState([]);

	/**
	 * Fetch user object from GraphQL query. This gets the data into data Object
	 * loading is used to show the loading icon/text while query is fetching the data
	 * @todo Need to remove the errors and put somewhere else
	 */
	/*const { loading, data } = useQuery(GET_PROFILE, {
		onError: ({ networkError, graphQLErrors }) => {
		//   console.log("graphQLErrors ** ", graphQLErrors);
		//   console.log("networkError ** ", networkError);
		},
		variables: { id: user._id },
	});*/

	useEffect(() => {
		const data = {
			with: ["workExperiences.company", "countryPhone", "workExperiences.industry", "workExperiences.country", "workExperiences.state", "workExperiences.city", "workExperiences.locality", "workExperiences.designation", "educations.institute", "educations.specialization", "educations.course", "accomplishments.type", "accomplishments.specialization", "projects", "skills", 'preferreds', 'city', 'country', 'state', 'locality']
		};
		ProfileDataService.fetchProfile(data)
			.then(response => {
				const data = response.data.data;
				let currDesignation = "Designation";
				let currCompany = "Current Company";

				data.work_experiences.forEach((exp, i) => {
					if (exp.isOnGoing === true) {
						currDesignation = exp?.designation?.title;
						currCompany = exp?.company?.title;
					}
				})
				const skillsArr = data.skills.map(s => ({ id: s.id, name: s.title }));
				const obj = {
					...data,
					userProfilePicture: null,
					full_name: `${data.firstName} ${data.lastName}`,
					name: `${data.firstName} ${data.lastName}`,
					gender: data.gender,
					address: data.address,
					email: data.email,
					phone: data.phone,
					countryPhone: data.country_phone ? data.country_phone.phonecode : '91',
					countryCode: data.countryPhoneId,
					locality: data.locality ? data.locality.name : '',
					pincode: data.locality ? data.locality.pincode : '',
					city: data.city ? data.city.name : '',
					state: data.state ? data.state.name : '',
					country: data.country ? data.country.name : '',
					hideEmailAndPhone: data.hideEmailAndPhone,
					emailVerified: data.emailVerified,
					phoneVerified: data.phoneVerified,
					currDesignation: currDesignation,
					curr_company: currCompany,
					resumeFileName: data.resume,
					// resume: data.resume,
					status: data.status,
					is_open_for_job: true,
					show_profile_in_ref: false,
					showEmail: data.showEmail,
					showPhone: data.showPhone,
					profile: {
						summary: data.summary,
						skills: data.skills.reduce((obj, item) => { obj.push(`${item.title} `); return obj }, []).join(', ').trim(),
						accomplishment: data.accomplishments,
						projects: data.projects,
						education: data.educations,
						work_experience: data.work_experiences,
					},
					skills: skillsArr,
					preferreds: data.preferreds.map(s => ({ id: s.city || s.state, name: s.city || s.state, city: s.city, state: s.state, country: s.country })),
				};
				setEditValues(obj);
			})
	}, []);

	useEffect(() => {
		const input = {
			start: 0,
			length: 100,
		}

		ProfileDataService.fetchIndustires(input)
			.then(response => {
				setindustries(response.data.data);
			})

	}, [])

	useEffect(() => {
		getLocation.GetAllCountries()
			.then(response => {
				if(response.data.status){
					setCountryCodes(response.data.data)
				}
			}).
			catch(error => console.log('country code err--',error));
	}, [])

	const onChangeHandler = (event) => {
		// console.log("event.target.checked:", event.target.checked,)
		// console.log("event.target.value:", event.target.value)
		// console.log("event.target.type:", event.target.type)
		// console.log(event.target.name);
		// console.log({editValues});
		switch (event.target.name) {
			case "is_open_for_job":
			case "show_profile_in_ref":
			case "showPhone":
				setEditValues({
					...editValues,
					[event.target.name]: event.target.checked,
				});
				break;
			case "showEmail":
				setEditValues({
					...editValues,
					[event.target.name]: event.target.checked,
				});
				break;

			case "gender":
				setEditValues({
					...editValues,
					[event.target.name]: parseInt(event.target.value)
				});
				break;

			case "summary":
				setEditValues({
					...editValues,
					profile: {
						...editValues.profile,
						summary: event.target.value
					}
				})
				break;
			/* case "countryCode":
				let getCountryIndex=countryCodes.findIndex(item => item.id == event.target.value);
				let getCountry=getCountryIndex > -1 ? countryCodes[getCountryIndex] : null;
				console.log(getCountry);
				setEditValues({
					...editValues,
					countryCode: event.target.value,
					country_phone: getCountry || "",
					countryPhone: getCountry ? getCountry['phonecode'] : ""
				})
				break; */

			case "skills":
				// const originalSkillsArr = editValues.skills;
				// const skillsStrArr = event.target.value.split(', ');

				setEditValues({
					...editValues,
					profile: {
						...editValues.profile,
						skills: event.target.value
					}
				})
				break;

			case "resume":
				// console.log("event.target====", event.target.files)
				setEditValues({
					...editValues,
					resumeFileName: event.target.files[0].name,
					resume: event.target.files[0]
				})
				break;

			default:
				setEditValues({
					...editValues,
					[event.target.name]: event.target.value,
				});
		}
	};

	const onUserProfileUpload = async (e) => {
		setEditValues({ ...editValues, userProfilePicture: e.target.files[0] });
		setEditValues({ ...editValues, picture: e.target.files[0] });
		// const formData = new FormData();
		// Update the formData object
		// formData.append(
		//   	"profilePicture",
		//   	e.target.files[0],
		// );
		// try {
		// 	await ProfileDataService.updateIndividualProfilePicture(formData);
		// } catch (error) {
		// 	console.log(error);
		// }
	}

	//Initialize temp work exp
	const tempWorkExperiece = {
		id: 0,
		"durationFrom": "",
		"durationTo": null,
		"currentCTC": 0,
		"isOnGoing": false,
		"email": "",
		"description": "",
		"duration_from_year": "",
		"duration_to_year": "",
		"duration_from_month": "",
		"duration_to_month": "",
		"company": {
			"id": null,
			"title": "",
			"profilePicture": ""
		},
		"industry": {
			"id": null,
			"title": ""
		},
		address: "",
		location: {
			city: "",
			locality: "",
			country: "",
			state: "",
			pincode: ""
		},
		"country": {
			"id": null,
			"name": ""
		},
		"state": {
			"id": null,
			"name": ""
		},
		"city": {
			"id": null,
			"name": ""
		},
		"pincode": null,
		"locality": "",
		"designation": {
			"id": null,
			"title": ""
		},
		job_type: "1",
		ctc_visible: true,
		emailVerified:0,
		email_verification_status:0,
		verification_email_sent:false,
	}

	const [editWEValues, setEditWEValues] = useState(tempWorkExperiece);

	//WE popup form change handler
	const onChangeWEHandler = (event) => {
		event.persist();
		// console.log("fieldName:", event.target.name)
		// console.log("value:", event.target.value)
		// console.log("check:", event.target.checked)

		switch (event.target.name) {
			case "durationFrom":
				const durationFrom = event.target.value;
				const arrDurationFrom = durationFrom ? durationFrom.split('-') : null;
				setEditWEValues({
					...editWEValues,
					duration_from_year: arrDurationFrom[0] || null,
					duration_from_month: arrDurationFrom[1] || null,
					durationFrom: durationFrom
				})
				break;

			case "durationTo":
				const durationTo = event.target.value;
				const arrDurationTo = durationTo ? durationTo.split('-') : null;
				setEditWEValues({
					...editWEValues,
					duration_to_year: arrDurationTo[0] || null,
					duration_to_month: arrDurationTo[1] || null,
					durationTo: durationTo
				})
				break;
			case "isOnGoing":
				setEditWEValues(prevEditValues => ({
					...prevEditValues,
					isOnGoing: event.target.checked
				}))
				break;
			case "email":
				setEditWEValues(prevEditValues => ({
					...prevEditValues,
					email:event.target.value,
					email_verification_status: 0
				}))
				break;
			case "email_verification_status":
				console.log('verified status 1--');
				if(editWEValues.email !== "")
					setEditWEValues(prevEditValues => ({
						...prevEditValues,
						email_verification_status: 1
					}))
					break;
			default:
				setEditWEValues({
					...editWEValues,
					[event.target.name]: event.target.value,
				});
		}
	};

	const editSuggesions = async (data, index, fieldName) => {
		// console.log("desigIndex:", index)
		// console.log("data:", data)
		// console.log("fieldName:", fieldName)

		switch (fieldName) {
			case "company":
				if (index !== -1) {
					const obj = editValues.profile.work_experience.map((we, weindex) => {
						if (weindex === index) {
							return {
								...we,
								company: {
									...we.company,
									id: data.id,
									title: data.title,
									profilePicture: data.profilePicture
								}
							}
						} else {
							return we;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						profile: {
							...prevEditValues.profile,
							work_experience: obj
						}
					}));
				} else {
					setEditWEValues({
						...editWEValues,
						company: {
							...editWEValues.company,
							id: data.id,
							title: data.title,
							profilePicture: data.profilePicture
						}
					})
				}
				break;
			case "designation":
			case "industry":
				if (index !== -1) {
					const obj = editValues.profile.work_experience.map((we, weindex) => {
						if (weindex === index) {
							return {
								...we,
								[fieldName]: {
									...we[fieldName],
									id: data.id,
									title: data.title
								}
							}
						} else {
							return we;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						profile: {
							...prevEditValues.profile,
							work_experience: obj
						}
					}));
				} else {
					setEditWEValues({
						...editWEValues,
						[fieldName]: {
							...editWEValues[fieldName],
							id: data.id,
							title: data.title
						}
					})
				}
				break;
			case "location":
				const location_obj = await googleAddrCall(data);
				if (index !== -1) {
					const locObj = editValues.profile.work_experience.map((we, weindex) => {
						if (weindex === index) {
							return {
								...we,
								address: data,
								locality: location_obj.locality,
								location: {
									...we.location,
									city: location_obj.city,
									locality: location_obj.locality,
									country: location_obj.country,
									state: location_obj.state,
									pincode: location_obj.pincode
								},
								city: {
									...we.city,
									name: location_obj.city
								},
								state: {
									...we.state,
									name: location_obj.state
								},
								country: {
									...we.country,
									name: location_obj.country
								}
							}
						} else {
							return we;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						profile: {
							...prevEditValues.profile,
							work_experience: locObj
						}
					}));
				} else {
					//   console.log("location_obj:", location_obj)

					//   console.log("locObj", locObj)
					setEditWEValues(prevEditValues => ({
						...prevEditValues,
						address: data,
						locality: location_obj.locality,
						location: {
							city: location_obj.city,
							locality: location_obj.locality,
							country: location_obj.country,
							state: location_obj.state,
							pincode: location_obj.pincode
						},
						pincode: location_obj.pincode,
						city: {
							name: location_obj.city
						},
						state: {
							name: location_obj.state
						},
						country: {
							name: location_obj.country
						}
					}));
				}
				break;

			default:
				setEditValues({
					...editValues,
					[fieldName]: data,
				});
		}
	}
	//   console.log("addtWEValues=", editWEValues);

	const onEditWEHandler = (event, index) => {
		// console.log(event.target);
		// console.log("desigIndex:", index)
		// console.log("editfieldName:", event.target.name)
		// console.log("editvalue:", event.target.value)

		switch (event.target.name) {
			case "isOnGoing":
				const isCurrentJob = editValues.profile.work_experience.map((we, weindex) => {
					if (weindex === index) {
						return {
							...we,
							isOnGoing: event.target.checked,
						}
					} else {
						return we;
					}
				})

				setEditValues(prevEditValues => ({
					...prevEditValues,
					profile: {
						...prevEditValues.profile,
						work_experience: isCurrentJob
					}
				}))
				break;
			case "email":
				const emailData = editValues.profile.work_experience.map((we, weindex) => {
					if (weindex === index) {
						return {
							...we,
							email: event.target.value,
							email_verification_status: 0
						}
					} else {
						return we;
					}
				})

				setEditValues(prevEditValues => ({
					...prevEditValues,
					profile: {
						...prevEditValues.profile,
						work_experience: emailData
					}
				}))
				break;
			case "email_verification_status":
				console.log('edit -- verified status 1--');
				if(editValues.email !== ""){
					const email_verification = editValues.profile.work_experience.map((we, weindex) => {
						if (weindex === index) {
							return {
								...we,
								email_verification_status: 1
							}
						} else {
							return we;
						}
					})
					setEditValues(prevEditValues => ({
						...prevEditValues,
						profile: {
							...prevEditValues.profile,
							work_experience: email_verification
						}
					}))

					break;
				}
			case "job_type":
				const job_typeData = editValues.profile.work_experience.map((we, weindex) => {
					if (weindex === index) {
						return {
							...we,
							job_type: event.target.value
						}
					} else {
						return we;
					}
				})

				setEditValues(prevEditValues => ({
					...prevEditValues,
					profile: {
						...prevEditValues.profile,
						work_experience: job_typeData
					}
				}))
				break;
			case "currentCTC":
				const cTCData = editValues.profile.work_experience.map((we, weindex) => {
					if (weindex === index) {
						return {
							...we,
							currentCTC: event.target.value
						}
					} else {
						return we;
					}
				})

				setEditValues(prevEditValues => ({
					...prevEditValues,
					profile: {
						...prevEditValues.profile,
						work_experience: cTCData
					}
				}))
				break;
			case "ctc_visible":
				const ctcVisible = editValues.profile.work_experience.map((we, weindex) => {
					if (weindex === index) {
						return {
							...we,
							ctc_visible: event.target.checked,
						}
					} else {
						return we;
					}
				})

				setEditValues(prevEditValues => ({
					...prevEditValues,
					profile: {
						...prevEditValues.profile,
						work_experience: ctcVisible
					}
				}))
				break;
			case "description":
				const data = editValues.profile.work_experience.map((we, weindex) => {
					if (weindex === index) {
						return {
							...we,
							[event.target.name]: event.target.value
						}
					} else {
						return we;
					}
				})

				setEditValues(prevEditValues => ({
					...prevEditValues,
					profile: {
						...prevEditValues.profile,
						work_experience: data
					}
				}))
				break;

			case "durationFrom":
				const durationFrom = event.target.value;
				const arrDurationFrom = durationFrom ? durationFrom.split('-') : null;
				const objFrom = editValues.profile.work_experience.map((we, weindex) => {
					if (weindex === index) {
						return {
							...we,
							duration_from_year: arrDurationFrom[0] || null,
							duration_from_month: arrDurationFrom[1] || null,
							durationFrom: durationFrom
						}
					} else {
						return we;
					}
				})
				setEditValues(prevEditValues => ({
					...prevEditValues,
					profile: {
						...prevEditValues.profile,
						work_experience: objFrom
					}
				}))
				break;

			case "durationTo":
				const durationTo = event.target.value;
				const arrDurationTo = durationTo ? durationTo.split('-') : null;
				const objTo = editValues.profile.work_experience.map((we, weindex) => {
					if (weindex === index) {
						return {
							...we,
							duration_to_year: arrDurationTo[0] || null,
							duration_to_month: arrDurationTo[1] || null,
							durationTo: durationTo
						}
					} else {
						return we;
					}
				})
				setEditValues(prevEditValues => ({
					...prevEditValues,
					profile: {
						...prevEditValues.profile,
						work_experience: objTo
					}
				}))
				break;

			default:
				setEditValues({
					...editValues,
					[event.target.name]: event.target.value,
				});
		}
	};

	//add work exp
	const addWorkExperiece = (event) => {
		// event.preventDefault();
		let old_value = editValues.profile.work_experience;

		old_value.push(editWEValues); //use for add new work exp

		setEditValues({
			...editValues,
			profile: {
				...editValues.profile,
				work_experience: old_value
			}
		});
	}

	const removeWorkExperience = index => {
		console.log('we-',index);
		const { profile: { work_experience } } = editValues;
		const list = filter(work_experience, (item,itemIndex) => itemIndex !== index);

		setEditValues({
			...editValues,
			profile: {
				...editValues.profile,
				work_experience: list
			}
		});
	}

	//add Education
	const addEducation = (event) => {
		// event.preventDefault();
		let old_value = editValues.profile.education;
		old_value.push(editEduValues); //use for add new work exp

		setEditEduValues({
			...editEduValues,
			// uuid: new Date().getTime()
		})

		setEditValues({
			...editValues,
			profile: {
				...editValues.profile,
				education: old_value
			}
		});
	}

	const removeEducation = index => {
		console.log('ed-',index);
		const { profile: { education } } = editValues;
		const list = filter(education, (item,itemIndex) => itemIndex !== index);

		setEditValues({
			...editValues,
			profile: {
				...editValues.profile,
				education: list
			}
		});
	}

	//add addAccomplishment
	const addAccomplishment = () => {
		const type = accomplishmentProjectVal.accmplishmentOptionType;

		const data = type === 'accomplishment' ? editValues.profile.accomplishment : editValues.profile.projects
		data.push(accomplishmentProjectVal);

		if (type === 'accomplishment') {
			setEditValues({
				...editValues,
				profile: {
					...editValues.profile,
					accomplishment: data
				}
			});
		} else {
			setEditValues({
				...editValues,
				profile: {
					...editValues.profile,
					projects: data
				}
			});
		}
	}

	const removeProject = index => {
		const { profile: { projects } } = editValues;
		const list = filter(projects, (item,itemIndex) => itemIndex !== index);

		setEditValues({
			...editValues,
			profile: {
				...editValues.profile,
				projects: list
			}
		});
	}

	const removeAccomplishment = index => {
		console.log(index);
		const { profile: { accomplishment } } = editValues;
		const list = filter(accomplishment, (item,itemIndex) => itemIndex !== index);

		setEditValues({
			...editValues,
			profile: {
				...editValues.profile,
				accomplishment: list
			}
		});
	}

	const updateProfile = async (event) => {
		event.preventDefault();

		// console.log("editvalues::", editValues);
		let cloneFormData = JSON.parse(JSON.stringify(editValues));
		// console.log("cloneFormData==>", cloneFormData);
		try{
			Object.keys(cloneFormData).forEach((key, value) => {
				if (key === "full_name") {
					const nameArr = cloneFormData[key].split(' ');
					cloneFormData["firstName"] = nameArr[0];
					cloneFormData["lastName"] = nameArr[1];
				}

				if (key === "profile") {
					cloneFormData["summary"] = cloneFormData[key].summary;
					// cloneFormData["skills"] = cloneFormData[key].skills;
					cloneFormData["work_experiences"] = cloneFormData[key].work_experience;
					if (cloneFormData[key].work_experience.length > 0) {
						cloneFormData[key].work_experience.forEach((v, i) => {
							cloneFormData["work_experiences"][i]["location"] = { locality: v.locality, country: v.country.name, state: v.state.name, city: v.city.name, pincode: "" };
						});
					}

					cloneFormData["educations"] = cloneFormData[key].education;
					console.log('🚀 ~ Object.keys ~ cloneFormData["educations"]:', cloneFormData["educations"]);
					if (cloneFormData[key].education.length > 0) {
						cloneFormData[key].education.forEach((v, i) => {
							if(!v.institute || !v.specialization) return;
							cloneFormData["educations"][i]["institute"] = { id: v.institute.id, title: v.institute.title, profilePicture: v.institute.profilePicture };
							cloneFormData["educations"][i]["specialization"] = { id: v.specialization.id, courseId: v.courseId, title: v.specialization.title };
						});
					}

					cloneFormData["accomplishments"] = cloneFormData[key].accomplishment;
					cloneFormData["projects"] = cloneFormData[key].projects;
					// delete cloneFormData[key];
				}

				// if (key === "countryPhone") {
				// 	// console.log("countryPhone", cloneFormData[key])
				// 	cloneFormData["countryPhone"] = cloneFormData[key];
				// }
				// if (key === "countryCode") {
				// 	// console.log("countryPhone", cloneFormData[key])
				// 	cloneFormData["countryCode"] = cloneFormData[key];
				// }
				if (key === "resume") {
					cloneFormData["resumeFileName"] = cloneFormData[key];
				}
			});

			if (editValues.picture) {
				cloneFormData.picture = editValues.picture
			}
			if (editValues.resume && typeof editValues.resume !== "string") {
				cloneFormData.resume = editValues.resume
			}else{
				delete cloneFormData.resume
			}

			const options = { indices: true, booleansAsIntegers: true };
			const formData = serialize(
				{ ...cloneFormData, ...{ "_method": "patch" } },
				options, // optional
			);

			console.log("formData", cloneFormData);
			// return false;

			try {
				await ProfileDataService.updateIndividualProfile(formData);
				Notify.success("Profile", "Profile updated successfully");
				// window.location.reload();
			} catch (error) {
				console.log(error);
			}
			console.log(" Final State", cloneFormData.profile.work_experience);
		}
		catch(e){
			console.log('🚀 ~ updateProfile ~ e:', e);
			Notify.error("Profile", "Something went wrong. Could not save profile");
		}

	};

	const editEduSuggesions = async (data, index, fieldName) => {
		// console.log("desigIndex:", index)
		// console.log("data:", data)
		// console.log("fieldName:", fieldName)
		// console.log("editValues:", editValues.profile.educations)

		switch (fieldName) {
			case "institute":
				if (index !== -1) {
					const obj = editValues.profile.education.map((edu, eduindex) => {
						if (eduindex === index) {
							return {
								...edu,
								"institute": {
									...edu["institute"],
									// instituteId: data.instituteId,
									id: data.id,
									title: data.title,
									profilePicture: data.profilePicture
								}
							}
						} else {
							return edu;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						profile: {
							...prevEditValues.profile,
							education: obj
						}
					}));
				} else {
					setEditEduValues(prevEditValues => ({
						...prevEditValues,
						"institute": {
							...prevEditValues["institute"],
							// instituteId: data.instituteId,
							id: data.id,
							title: data.title,
							profilePicture: data.profilePicture
						}
					}));
				}
				break;
			case "type":
				if (index !== -1) {
					const setJobType = editValues.profile.education.map((we, weindex) => {
						if (weindex === index) {
							return {
								...we,
								type: data
							}
						} else {
							return we;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						profile: {
							...prevEditValues.profile,
							education: setJobType
						}
					}))
				} else {
					setEditEduValues(prevEditValues => ({
						...prevEditValues,
						type: data
					}));
				}
				break;
			case "course":
				if (index !== -1) {
					const courseObj = editValues.profile.education.map((we, eduindex) => {
						if (eduindex === index) {
							return {
								...we,
								"course": {
									...we["course"],
									// instituteId: data.id,
									id: data.id,
									title: data.title
								}
							}
						} else {
							return we;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						profile: {
							...prevEditValues.profile,
							education: courseObj
						}
					}));
				} else {
					setEditEduValues(prevEditValues => ({
						...prevEditValues,
						"course": {
							...prevEditValues["course"],
							// instituteId: data.id,
							id: data.id,
							title: data.title
						}
					}));
				}
				break;
			case "specialization":
				if (index !== -1) {
					const specializationObj = editValues.profile.education.map((we, eduindex) => {
						if (eduindex === index) {
							return {
								...we,
								"specialization": {
									...we["specialization"],
									id: data.id,
									title: data.title,
									courseId: data.courseId
								}
							}
						} else {
							return we;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						profile: {
							...prevEditValues.profile,
							specialization: specializationObj
						}
					}));
				} else {
					setEditEduValues(prevEditValues => ({
						...prevEditValues,
						"specialization": {
							...prevEditValues["specialization"],
							id: data.id,
							title: data.title,
							courseId: data.courseId
						}
					}));
				}
				break;

			case "isOnGoing":
				if (index !== -1) {
					const isCurrentJob = editValues.profile.education.map((we, weindex) => {
						if (weindex === index) {
							return {
								...we,
								isOnGoing: data
							}
						} else {
							return we;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						profile: {
							...prevEditValues.profile,
							education: isCurrentJob
						}
					}))
				} else {
					setEditEduValues(prevEditValues => ({
						...prevEditValues,
						isOnGoing: data
					}));
				}
				break;
			case "durationFrom":
				const durationFrom = data;
				const arrDurationFrom = durationFrom ? durationFrom.split('-') : null;
				if (index !== -1) {
					const objFrom = editValues.profile.education.map((we, weindex) => {
						if (weindex === index) {
							return {
								...we,
								duration_from_year: arrDurationFrom[0] || null,
								duration_from_month: arrDurationFrom[1] || null,
								durationFrom: durationFrom
							}
						} else {
							return we;
						}
					})
					setEditValues(prevEditValues => ({
						...prevEditValues,
						educations: {
							...prevEditValues.educations,
							educations: objFrom
						}
					}))
				} else {
					setEditEduValues(prevEditValues => ({
						...prevEditValues,
						duration_from_year: arrDurationFrom[0] || null,
						duration_from_month: arrDurationFrom[1] || null,
						durationFrom: durationFrom
					}))
				}
				break;

			case "durationTo":
				const durationTo = data;
				const arrDurationTo = durationTo ? durationTo.split('-') : null;
				if (index !== -1) {
					const objTo = editValues.profile.education.map((we, weindex) => {
						if (weindex === index) {
							return {
								...we,
								duration_to_year: arrDurationTo[0] || null,
								duration_to_month: arrDurationTo[1] || null,
								durationTo: durationTo
							}
						} else {
							return we;
						}
					})
					setEditValues(prevEditValues => ({
						...prevEditValues,
						educations: {
							...prevEditValues.educations,
							educations: objTo
						}
					}))
				} else {
					setEditEduValues(prevEditValues => ({
						...prevEditValues,
						duration_to_year: arrDurationTo[0] || null,
						duration_to_month: arrDurationTo[1] || null,
						durationTo: durationTo
					}))
				}
				break;

			default:
				setEditValues({
					...editValues,
					[fieldName]: data,
				});
		}
	};

	//temp education initial data
	const tempEducation = {
		course: { id: 0, title: "" },
		courseId: null,
		created_at: null,
		durationFrom: "",
		durationTo: "",
		duration_from_month: "",
		duration_from_year: "",
		duration_to_month: "",
		duration_to_year: "",
		id: 0,
		institute: { id: 0, title: "", profilePicture: "" },
		instituteId: null,
		isOnGoing: false,
		type: 1,
		spcializationId: null,
		specialization: { id: 0, title: "" },
		updated_at: null,
		userId: null,

	}
	const [editEduValues, setEditEduValues] = useState(tempEducation);

	//temp projects initial data
	const tempAccomplishmentProject = {
		accmplishmentOptionType: "project",
		type: {
			id: 0,
			title: ""
		},
		description: "",
		durationFrom: "",
		durationTo: "",
		duration_from_month: "",
		duration_from_year: "",
		duration_to_month: "",
		duration_to_year: "",
		id: 0,
		isOnGoing: false,
		title: "",

	}

	const [accomplishmentProjectVal, setAccomplishmentProjectVal] = useState(tempAccomplishmentProject);

	const childWEToCall = {
		onChangeWEHandler: onChangeWEHandler,
		onEditWEHandler: onEditWEHandler,
		editSuggesions: editSuggesions,
		industries: industries,
		editWEValues: editWEValues,
		workExperienceNewState: editValues.profile.work_experience
	};

	const childEduToCall = {
		editEduValues: editEduValues,
		editEduSuggesions: editEduSuggesions
	}

	const editAccomSuggesions = async (data, index, fieldName) => {
		// console.log("desigIndex:", index)
		// console.log("data:", data)
		// console.log("fieldName:", fieldName)

		if (index !== -1) {
			const obj = editValues.profile.accomplishment.map((we, weindex) => {
				if (weindex === index) {
					return {
						...we,
						[fieldName]: {
							...we[fieldName],
							id: data.id,
							title: data.title
						}
					}
				} else {
					return we;
				}
			})

			setEditValues(prevEditValues => ({
				...prevEditValues,
				profile: {
					...prevEditValues.profile,
					accomplishment: obj
				}
			}));
		} else {
			setAccomplishmentProjectVal({
				...accomplishmentProjectVal,
				[fieldName]: {
					...accomplishmentProjectVal[fieldName],
					id: data.id,
					title: data.title
				}
			})
		}

	}

	const onChangeAccomplishment = (event, index = -1, accomType) => {
		event.persist();
		// const index = index ? index : parseInt("-1");
		// console.log("fieldName:", event.target.name)
		// console.log("value:", event.target.value)
		// console.log("check:", event.target.checked)
		// console.log("index:", index)
		// console.log("[accomType]:", accomType)
		// let forType = accomType

		switch (event.target.name) {
			case "accmplishmentOptionType":
				setAccomplishmentProjectVal({
					...accomplishmentProjectVal,
					accmplishmentOptionType: event.target.value
				})
				break;
			case "isOnGoing":
				if (index === -1) {
					setAccomplishmentProjectVal({
						...accomplishmentProjectVal,
						isOnGoing: event.target.checked
					})
				} else {
					const obj = editValues.profile[accomType].map((we, weindex) => {
						if (weindex === index) {
							return {
								...we,
								isOnGoing: event.target.checked
							}
						} else {
							return we;
						}
					})
					setEditValues(prevEditValues => ({
						...prevEditValues,
						profile: {
							...prevEditValues.profile,
							[accomType]: obj
						}
					}));
				}
				break;
			case "durationFrom":
				const durationFrom = event.target.value;
				const arrDurationFrom = durationFrom ? durationFrom.split('-') : null;
				if (index === -1) {
					setAccomplishmentProjectVal({
						...accomplishmentProjectVal,
						duration_from_year: arrDurationFrom[0] || null,
						duration_from_month: arrDurationFrom[1] || null,
						durationFrom: durationFrom
					})
				} else {
					const obj = editValues.profile.accomType.map((we, weindex) => {
						if (weindex === index) {
							return {
								...we,
								duration_from_year: arrDurationFrom[0] || null,
								duration_from_month: arrDurationFrom[1] || null,
								durationFrom: durationFrom
							}
						} else {
							return we;
						}
					})
					setEditValues(prevEditValues => ({
						...prevEditValues,
						profile: {
							...prevEditValues.profile,
							[accomType]: obj
						}
					}));
				}
				break;
			case "durationTo":
				const durationTo = event.target.value;
				const arrDurationTo = durationTo ? durationTo.split('-') : null;
				if (index === -1) {
					setAccomplishmentProjectVal({
						...accomplishmentProjectVal,
						duration_to_year: arrDurationTo[0] || null,
						duration_to_month: arrDurationTo[1] || null,
						durationTo: durationTo
					})
				} else {
					const obj = editValues.profile.accomType.map((we, weindex) => {
						if (weindex === index) {
							return {
								...we,
								duration_to_year: arrDurationTo[0] || null,
								duration_to_month: arrDurationTo[1] || null,
								durationTo: durationTo
							}
						} else {
							return we;
						}
					})
					setEditValues(prevEditValues => ({
						...prevEditValues,
						profile: {
							...prevEditValues.profile,
							[accomType]: obj
						}
					}));
				}
				break;

			default:
				if (index === -1) {
					setAccomplishmentProjectVal({
						...accomplishmentProjectVal,
						[event.target.name]: event.target.value
					})
				} else {
					const obj = editValues.profile[accomType].map((we, weindex) => {
						if (weindex === index) {
							return {
								...we,
								[event.target.name]: event.target.value
							}
						} else {
							return we;
						}
					})
					setEditValues(prevEditValues => ({
						...prevEditValues,
						profile: {
							...prevEditValues.profile,
							[accomType]: obj
						}
					}));
				}

				break;
		}
	}

	const childAccomProjectToCall = {
		accomplishmentProjectVal: accomplishmentProjectVal,
		onChangeAccomplishment: onChangeAccomplishment,
		editAccomSuggesions: editAccomSuggesions,
		accomplishmentNewState: editValues.profile.accomplishment
	}

	const chRef = {
		addWorkExperiece: addWorkExperiece,
		addEducation: addEducation,
		addAccomplishment: addAccomplishment,
		removeWorkExperience: removeWorkExperience,
		removeEducation: removeEducation,
		removeAccomplishment: removeAccomplishment,
		removeProject: removeProject,
	};

	const [address, setAddress] = useState("");
	const [jobLocation, setJobLocation] = useState("");
	const [suggestions, setSuggestions] = useState([]);

	const handleSelect = async value => {
		const location_obj = await googleAddrCall(value);
		setAddress(value);
		setEditValues({ ...editValues, address: value, city: location_obj.city, locality: location_obj.locality, country: location_obj.country, state: location_obj.state, pincode: location_obj.pincode });
	}

	const handleSelectPlaces = async value => {
		const location_obj = await googleAddrCall(value);
		// console.log("location_obj==", location_obj)
		setJobLocation("");

		const oldLocations = [...editValues.preferreds];
		oldLocations.push({
			city: location_obj.city,
			state: location_obj.state,
			country: location_obj.country,
			id: location_obj.city,
			name: location_obj.city || location_obj.state
		})
		setEditValues({
			...editValues,
			preferreds: oldLocations
		})
	}
	//skills auto suggest section
	const reactTags = useRef()
	const reactAddressTags = useRef()

	async function onInput(query) {
		// console.log("query==", query)
		// if (!loader) {
		// setLoder(true)
		const input = {
			start: 0,
			length: 20,
			search: query
		}
		return await ProfileDataService.fetchSkills(input)
			.then((result) => {
				// console.log(">>>>>>>>>>>", result);
				setSuggestions(
					result.data.data.map((item) => ({
						id: item.id,
						name: item.title
					}))
				);
				// setLoder(flase)
			})
	}
	const onDelete = (i) => {
		const skillsTags = editValues.skills.slice(0);
		skillsTags.splice(i, 1);
		setEditValues({
			...editValues,
			profile: {
				...editValues.profile,
				skills: skillsTags
			},
			skills: skillsTags
		})
	}

	const onAddition = (tag) => {
		const skillsTags = [].concat(editValues.skills, tag);
		// console.log("onDelete:", skillsTags)
		setEditValues({
			...editValues,
			profile: {
				...editValues.profile,
				skills: skillsTags
			},
			skills: skillsTags
		})
	}

	const onDeleteLocation = (i) => {
		const LocationsTags = editValues.preferreds.slice(0);
		LocationsTags.splice(i, 1);
		setEditValues({
			...editValues,
			preferreds: LocationsTags
		})
	}

	const onAddLocation = (tag) => {
		const LocationsTags = [].concat(editValues.preferreds, tag);
		// console.log("onDelete:", LocationsTags)
		setEditValues({
			...editValues,
			preferreds: LocationsTags
		})
	}

	const hideVerificationModal = () =>{
		setShowPhoneVerification(false);
	}


	//   console.log("editValues==", editValues);
	//   console.log("suggestions:", suggestions);

	return (

		<React.Fragment>
			{showPreview ? (
				<Profile profileValues={editValues} previewThis={showPreview} togglePreview={setShowPrevew} />
			) : (
				<Container className="edit-profile ind-edit-profile">
					<Row>
						<Col lg={4} className="position-lg-sticky">
							<Card className="profileCard mb-3 relative">
								<div className="userPic">
									<img alt="pic" src={editValues.picture ? URL.createObjectURL(editValues.picture) : `${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${editValues.profilePicture}`} />
									<Button variant="light">
										<input
											type="file"
											name="userProfilePic"
											id="userProfilePic"
											accept="image/png,image/x-png,image/jpg,image/jpeg"
											onChange={onUserProfileUpload} />
										<i className="fa fa-camera"></i>
									</Button>
								</div>
								{/* pic */}
								<h3>
									{editValues.full_name}
									<small>
										{editValues.currDesignation
											? editValues.currDesignation
											: "Designation"}
									</small>
									<small>
										{editValues.curr_company
											? editValues.curr_company
											: "Current Company"}
									</small>
								</h3>
								<ul className="fa-ul">
									<li>
										<i className="far fa-li fa-envelope"></i>
										{editValues.email}
										{editValues.emailVerified && (
											<span className="float-right">
												<i className="fa fa-check text-center"></i>
											</span>
										)}
									</li>
									<li>
										<i className="fa fa-li fa-phone"></i>
										{editValues.phone ? `${editValues.countryPhone} - ${editValues.phone}` : 'N/A'}
										{editValues.phoneVerified && (
											<span className="float-right">
												<i className="fa fa-check text-center"></i>
											</span>
										)}
									</li>
									<li>
										<i className="fa fa-li fa-map-marker-alt"></i>
										{editValues.locality ? `${editValues.locality},  ${editValues.city}` : "Your Address"}
									</li>
								</ul>
								{/* <hr />
								<h5>Summary</h5>
								<p>
									{editValues.profile.summary
										? editValues.profile.summary
										: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."}
								</p> */}
							</Card>
							{["checkbox"].map((type) => (
								<div key={`custom-${type}`}>
									<Form.Check
										custom
										className="col"
										type={type}
										id="is_open_for_job"
										name="is_open_for_job"
										label="I'm open for job change"
										onChange={onChangeHandler}
										checked={editValues.is_open_for_job}
									/>
								</div>
							))}
							<Accordion defaultActiveKey="0">
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey="0"
									className="w-100 p-0"
								>
									<h5 className="my-3">
										Show my contact details
										<i className="fa fa-angle-down float-right"></i>
									</h5>
								</Accordion.Toggle>
								<Accordion.Collapse eventKey="0">
									<Card.Body className="p-0">
										{["checkbox"].map((type) => (
											<div key={`custom-${type}`} className="pl-3">
												<Form.Check
													custom
													type={type}
													id="showEmail"
													name="showEmail"
													label="Email id"
													onChange={onChangeHandler}
													checked={editValues.showEmail}
													className="mt-2"
												/>
												<Form.Check
													custom
													type={type}
													id="showPhone"
													name="showPhone"
													label="Mobile No"
													onChange={onChangeHandler}
													checked={editValues.showPhone}
													className="mt-2"
												/>
											</div>
										))}
									</Card.Body>
								</Accordion.Collapse>
							</Accordion>

							<h5 className="mt-3">
								Looking for opportunities
								<i className="fa fa-angle-down float-right"></i>
							</h5>
							{["checkbox"].map((type) => (
								<div key={`custom-${type}`} className="mt-3 pl-3">
									<Form.Check
										custom
										type={type}
										id="show_profile_in_ref"
										name="show_profile_in_ref"
										label="Show my profile in Referral suggestions"
										onChange={onChangeHandler}
										checked={editValues.show_profile_in_ref}
										className="my-2"
									/>
									<Form.Check
										custom
										type={type}
										id="scan_connection"
										name="scan_connection"
										label="Scan my connections for any
										referral opportunity and suggest
										me matches."
										onChange={onChangeHandler}
										className="my-2"
									// checked={editValues.show_profile_in_ref}
									/>
									<Form.Check
										custom
										type={type}
										id="looking_job"
										name="looking_job"
										label="I am looking for a job, so help me."
										onChange={onChangeHandler}
										className="my-2"
									// checked={editValues.show_profile_in_ref}
									/>
									<Form.Check
										custom
										type={type}
										id="looking_job_not_rush"
										name="looking_job_not_rush"
										label="I am looking for a job, but not in a rush."
										onChange={onChangeHandler}
										className="my-2"
									// checked={editValues.show_profile_in_ref}
									/>
									<Form.Check
										custom
										type={type}
										id="connection_see_contact"
										name="connection_see_contact"
										label="It's okay, let my connections see my
										contact details?"
										onChange={onChangeHandler}
										className="my-2"
									// checked={editValues.show_profile_in_ref}
									/>
									<Form.Check
										custom
										type={type}
										id="allow_other_connect"
										name="allow_other_connect"
										label="Allow others to connect with me
										through the help of mutual connects."
										onChange={onChangeHandler}
										className="my-2"
									// checked={editValues.show_profile_in_ref}
									/>
									<Form.Check
										custom
										type={type}
										id="allow_connnection_refer"
										name="allow_connnection_refer"
										label="Allow my network to refer me."
										onChange={onChangeHandler}
										className="my-2"
									// checked={editValues.show_profile_in_ref}
									/>
									<Form.Check
										custom
										type={type}
										id="contact_details_confirmation"
										name="contact_details_confirmation"
										label="Ask me before showing my contact
										details to employers"
										onChange={onChangeHandler}
										className="my-2"
									// checked={editValues.show_profile_in_ref}
									/>
								</div>
							))}
						</Col>
						<Col lg={8} className="mt-3 mt-lg-0">
							<Form noValidate onSubmit={updateProfile}>
								<Card className="card-profile-edit-form">
									<Form.Group>
										<Form.Label>
											Full Name<span className="text-danger">*</span>
										</Form.Label>
										<Form.Control
											type="text"
											name="full_name"
											id="full_name"
											onChange={onChangeHandler}
											value={editValues.full_name || ""}
										></Form.Control>
									</Form.Group>
									<Form.Group>
										<Form.Label>
											Gender<span className="text-danger">*</span>
										</Form.Label>
										{["radio"].map((type) => (
											<div key={`custom-${type}`} className="d-flex">
												<Form.Check
													custom
													className="col-md-auto"
													type={type}
													id="male"
													name="gender"
													label="Male"
													value={1}
													onChange={onChangeHandler}
													checked={editValues.gender === 1}
												/>
												<Form.Check
													custom
													className="col-md-auto"
													type={type}
													id="female"
													name="gender"
													label="Female"
													value={2}
													onChange={onChangeHandler}
													checked={editValues.gender === 2}
												/>
												<Form.Check
													custom
													className="col-md-auto"
													type={type}
													id="other"
													name="gender"
													label="Other"
													value={3}
													onChange={onChangeHandler}
													checked={editValues.gender === 3}
												/>
											</div>
										))}
									</Form.Group>
									<Form.Group>
										<Form.Label>
											Address<span className="text-danger">*</span>
										</Form.Label>
										{
											<PlacesAutocomplete
												autocomplete="off"
												placeholder="PlacesAutocomplete"
												value={address ? address : editValues.address}
												onChange={setAddress}
												onSelect={handleSelect}
											>
												{placeGoogle}
											</PlacesAutocomplete>
										}
									</Form.Group>
									<Row>
										<Col md={6}>
											<Form.Group>
												<Form.Label>
													Locality<span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													name="locality"
													id="locality"
													onChange={onChangeHandler}
													value={editValues.locality || ""}
												></Form.Control>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group>
												<Form.Label>
													City<span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													name="city"
													id="city"
													onChange={onChangeHandler}
													value={editValues.city || ""}
												></Form.Control>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group>
												<Form.Label>
													State<span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													name="state"
													id="state"
													onChange={onChangeHandler}
													value={editValues.state || ""}
												></Form.Control>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group>
												<Form.Label>
													Country<span className="text-danger">*</span>
												</Form.Label>
												<Form.Control
													type="text"
													name="country"
													id="country"
													onChange={onChangeHandler}
													value={editValues.country || ""}
												></Form.Control>
											</Form.Group>
										</Col>
									</Row>
									<Row className="align-items-end mb-3">
										<Col md={editValues.phoneVerified===0 ? 3 : 4}>
											<Form.Group className="mb-0">
												<Form.Label>Pincode</Form.Label>
												<InputGroup>
													<Form.Control
														type="text"
														name="pincode"
														id="pincode"
														onChange={onChangeHandler}
														value={editValues.pincode || ""}
													></Form.Control>
												</InputGroup>
											</Form.Group>
										</Col>
										<Col md={editValues.phoneVerified===0 ? 6 : 8}>
											<Form.Label>Mobile no</Form.Label>
											<Row className={editValues.phoneVerified===0 && "no-gutters"}>
												<Col md={6}>
													<Form.Control
														as="select"
														custom
														name="countryCode"
														id="countryCode"
														style={{height:"43px"}}
														onChange={ onChangeHandler }
														value={ editValues.countryCode || '' }
														disabled={ editValues.phoneVerified === 1 }
													>
														<option value="">Country Code</option>
														{ countryCodes.map(item => <option key={ `country_code_${ item.phonecode }` } value={ item.phoneCode } >({ item.phonecode }) { item.name }</option>) }
													</Form.Control>
												</Col>
												<Col md={6}>
													<Form.Control
														className="p-2"
														type="text"
														name="phone"
														id="phone"
														onChange={onChangeHandler}
														value={editValues.phone || ""}
														disabled={editValues.phoneVerified===1}
													></Form.Control>
												</Col>
											</Row>
										</Col>
										{editValues.phoneVerified===0 && (
											<Col md={1}>
												<Button className="btn-verify" type="button" variant="outline-success" onClick={()=>setShowPhoneVerification(true)}>
													VERIFY
												</Button>
											</Col>
										)}
									</Row>

									<Form.Group>
										<Form.Label>
											Profile summary<span className="text-danger">*</span>
										</Form.Label>
										<Form.Control
											as="textarea"
											type="text"
											name="summary"
											id="summary"
											rows="4"
											onChange={onChangeHandler}
											value={editValues.profile.summary || ""}
										></Form.Control>
									</Form.Group>

								</Card>
								{
									showPhoneVerification && (
										<PhoneVerificationModal
											isShowModal={showPhoneVerification}
											hideVerificationModal={hideVerificationModal}
											fullMobileNo={`${ editValues.countryCode } ${ editValues.phone }`}
											value={ editValues.phone }
											countryCode={ editValues.countryCode }
										/>
									)
								}
								<WorkExperienceContext.Provider value={childWEToCall}>
									<h3>
										Work experience
										<WorkExperienceModal index={-1} childWEToCall={childWEToCall} chRef={chRef} />
									</h3>
									<WorkEmailVerificationModal {...props} />

									{
										editValues.profile.work_experience.length > 0 ?
											/* Work Experience Listing */
											editValues.profile.work_experience.map((item, index) => {
												return <WorkExperienceCard index={index} key={index} weItem={item} showPreview={showPreview} removeWorkExperience={removeWorkExperience} />;
											}) :
											<div className="no-data">
												You don't have any work experience yet!
												<br />
												Please add it
					  						</div>
									}
								</WorkExperienceContext.Provider>
								<Form.Group>
									<h3>Preferred Location<span className="text-danger">*</span></h3>
									{editValues.preferreds.length > 0 ?
										<ReactTags
											placeholderText="Add New Location"
											ref={reactAddressTags}
											tags={editValues.preferreds}
											onDelete={onDeleteLocation}
											onAddition={onAddLocation} /> : ""}

									{
										<PlacesAutocomplete
											autocomplete="off"
											value={jobLocation}
											onChange={setJobLocation}
											onSelect={handleSelectPlaces}
										>
											{placeGoogle}
										</PlacesAutocomplete>
									}

								</Form.Group>

								<EducationContext.Provider value={childEduToCall}>
									<h3>Education <Education index={-1} childEduToCall={childEduToCall} chRef={chRef} /></h3>
									{editValues.profile.education.length > 0 ?
										/* education card Listing */
										editValues.profile.education.map((item, index) => {
											return <EducationCard index={index} key={index} eduItem={item} removeEducation={removeEducation} showPreview={showPreview} />;
										}) :
										<div className="no-data">
											Add your education details.
											<br />
											Which will help to your connections for reference
										</div>
									}
								</EducationContext.Provider>

								{/* no-data */}
								<h3>Accomplishment <Accomplishment index={-1} childAccomProjectToCall={childAccomProjectToCall} chRef={chRef} /></h3>
								<AccomplishmentContext.Provider value={childAccomProjectToCall}>
									<ProjectsCollapse projects={editValues.profile.projects} removeProject={removeProject} showPreview={showPreview} />
									<AccomplishmentCollapse accomplishment={editValues.profile.accomplishment} removeAccomplishment={removeAccomplishment} showPreview={showPreview} />
								</AccomplishmentContext.Provider>

								{/* no-data */}
								<Form.Group>
									<h3>Skills<span className="text-danger">*</span></h3>
									<ReactTags
										// inline={false}
										ref={reactTags}
										tags={editValues.skills}
										onInput={onInput}
										suggestions={suggestions}
										onDelete={onDelete}
										onAddition={onAddition}
									/>
								</Form.Group>
								<Form.Group className="my-5 text-center">
									{/* <Form.Control
					  id="custom-file"
					  type="file"
					  name="resume"
					  label={
						<div>
						  <i className="fa fa-cloud-upload mr-3"></i>
						Upload resume<span className="text-danger">*</span>
						</div>
					  }
					  // accept=".pdf"
					  onChange={onChangeHandler}
					/> */}
									<Form.Label className="mr-2">
										{editValues.resumeFileName ? editValues.resumeFileName : "No"}
									</Form.Label>
									<Form.File
										id="custom-file"
										required
										name="resume"
										label={
											<div>
												<i className="fa fa-cloud-upload-alt fa-lg mr-3"></i>
												Upload resume<span className="text-danger">*</span>
											</div>
										}
										custom
										onChange={onChangeHandler}
									/>


								</Form.Group>
								<div className="fixedButtons">
									<Button
										className="btn btn-dark-o mr-2 px-5"
										onClick={() => setShowPrevew(true)}
									>
										Preview
				  					</Button>
									<Button variant="dark" type="submit" className=" px-5">
										Save
				  					</Button>
								</div>
								{/* fixedbuttons */}
							</Form>
						</Col>
					</Row>
				</Container>
			)}
		</React.Fragment>
	);
	//}
}
