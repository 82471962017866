import React, { Component, useContext, useEffect, useState } from 'react';
import { Link, Redirect, Router } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Collapse, Modal } from 'react-bootstrap';
import SignupComponent from './signup';
import AuthService from '../../../services/AuthService'
import { AuthContext } from "../../../context/auth";
import { LoginValidation } from '../../Common/Validation';


import { Formik, Field } from 'formik';
import * as Yup from 'yup';

function LoginComponent(props) {
    const [errors, setErrors] = useState({})
    const { user, login } = useContext(AuthContext);
    const [formData, setFormData] = useState({ email: "", password: "", fcmToken: "", });
    const [active, toggleActive] = useState(false);

    const redirectToHome = (user) => {
        const {guard, redirectTo} = user;
        const redirectToEditProfile = redirectTo ? redirectTo.includes("profile-edit") : false
        switch (guard) {
            case "user":
                props.history.push(redirectToEditProfile ? "/individual/edit-profile" : "/individual/home");
                break;

            case "institute":
                props.history.push(redirectToEditProfile ? "/institute/profile/edit" : "/institute/home/wall");
                break;

            case "company":
                props.history.push(redirectToEditProfile ? "/corporate/profile/edit" : "/corporate/home/wall");
                break;
            // default: break;

            case "admin":
                props.history.push("/admin/home");
                break;
            default: break;
        }
    }

    const getInitialState = () => {
        return {
            selectedOption: 'individual'
        };
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        AuthService.commonLogin(formData)
            .then(response => {
                if (response.data.success) {
                    const user = {
                        token: response.data.token,
                        guard: response.data.guard,
                        userDetails: response.data.user,
                        data: formData.email,
                        redirectTo: response.data.redirectTo,
                    }
                    login(user)
                    redirectToHome(user)
                } else {
                    console.log("Failed: ", response)
                }
            })
            .catch(e => {
                console.log(e);
                if (e && e.data.errors) {
                    setErrors(e.data.errors)
                }
            })
    }

    // render() {
    // const redirectToHome = this.state.redirectToHome;
    //  if (redirectToHome === true) {
    //  }

    useEffect(() => {
        if (user && user.userDetails) {
            redirectToHome(user);
        }
    }, [user]);

    return (
        <React.Fragment>
            <div className="login">
                <Container fluid>
                    <Row className="justify-content-md-end">
                        <Col md={'auto'} className="pt-3">
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col className="px-1">
                                        <Form.Group className="mb-0">
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter Email"
                                                value={formData.email}
                                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}>
                                            </Form.Control>
                                            <div className="errorClassW" >  {errors.email} </div>
                                        </Form.Group>
                                    </Col>
                                    <Col className="px-1">
                                        <Form.Group className="mb-0">
                                            <Form.Control
                                                type="password"
                                                placeholder="Enter Password"
                                                value={formData.password}
                                                onChange={(e) => setFormData({ ...formData, password: e.target.value })}>
                                            </Form.Control>
                                            <div className="errorClassW"> {errors.password}</div>
                                            <ForgotPassword />
                                            <ChangePasswordModal {...props} />
                                            <UserEmailVerify {...props} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={'auto'} className="px-1">
                                        <Button type="submit" variant="orange">Login</Button>
                                    </Col>
                                    <Col md={'auto'} className="px-1">
                                        <Button variant="orange-o" onClick={() => toggleActive(!active)}>Sign Up</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    <div className={active ? "signupRightSheet active" : "signupRightSheet"}>
                        <div className="sheetBody">
                            <Button className="close" onClick={() => toggleActive(!active)}>X</Button>
                            <SignupComponent />
                        </div>{/* body */}
                    </div>{/* rightsheet */}
                </Container>
            </div>
        </React.Fragment>
    );
}
export default LoginComponent;


function ForgotPassword() {
    const [show, setShow] = React.useState(false);
    const [errors, setErrors] = useState({})
    const { user, login } = useContext(AuthContext);
    const handleCloseEdu = () => setShow(false);
    const handleShowEdu = () => setShow(true);


    const handleForgetSubmit = async (event) => {
        event.preventDefault();
		console.log(formData);
        AuthService.checkEmail(null, formData)
            .then(response => {
                if (response.data.success) {
                    login(user)
                    setShow(false);

                } else {
                    console.log("Failed: ", response)
                }
            })
            .catch(e => {
                console.log(e);
                if (e && e.data.errors) {
                    setErrors(e.data.errors)
                }
            })
    }

    const [formData, setFormData] = useState({ email: "", password: "", fcmToken: "", });

    return (
        <>
            <Form.Text onClick={handleShowEdu} className="text-light pointer">Forgot Password?</Form.Text>
			<Modal show={show} onHide={handleCloseEdu} centered size="md">
				<Modal.Header closeButton>
					<Modal.Title>Forgot password?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						Forgot password? don't worry it happens usually with our engineers also. Just enter your registered email id & we will send you a password reset link.
						It's that easy!
					</p>
					<Form.Group>
						<Form.Label>Email id <span className="text-danger">*</span></Form.Label>
						<Form.Control type="email" onChange={(e) => setFormData({ ...formData, email: e.target.value })}></Form.Control>
					</Form.Group>
					<div className="text-center pt-3">
						<Button  type="button"  variant="dark" onClick={handleForgetSubmit}>Reset Password</Button>
					</div>{/* center */}
				</Modal.Body>
			</Modal>
        </>
    );
}

function ChangePasswordModal(props){
    // console.log(props);
    const [ChangePassFormData, setChangePassFormData] = useState({ password: "",password_confirmation:"",token:""});
    const [showChangePassModal, setShowChangePassModal] = useState(false);
    useEffect(() => {
        let urlParams = new URLSearchParams(props.location.search);
        let code = urlParams.get('code');  
        
        if( code !=null){  
            AuthService.checkTokenValidation(null,{token:code})
            .then((response) => {
                if(response.status == 200 && response.data.success){
                    setShowChangePassModal(true);
                    setChangePassFormData({...ChangePassFormData,token:code});
                }
            })
            .catch((error)=>{
                console.log('error--',error);
            });
        }
    }, []);

    const handleChangePassword = () => {
        AuthService.resetPassword(null,ChangePassFormData)
        .then((response) => {
            if(response.status >= 200 && response.data.success){
                setChangePassFormData({ password: "",password_confirmation:"", token:""});
                setShowChangePassModal(false);
                props.history.push("/");
            }
        })
        .catch((error)=>{
            console.log('error--',error);
        });
    }

    const handleCloseChangePassModal = () => {
        if(window.confirm("Are you sure?")){
            setShowChangePassModal(false);
            props.history.push("/");
        }
    }

    return (
        <>
            <Modal show={showChangePassModal} backdrop="static" keyboard={false} onHide={handleCloseChangePassModal} centered size="md">
				<Modal.Header closeButton>
					<Modal.Title>Change password?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label>Password <span className="text-danger">*</span></Form.Label>
						<Form.Control type="password" onChange={(e) => setChangePassFormData({ ...ChangePassFormData, password: e.target.value })}></Form.Control>
					</Form.Group>
					<Form.Group>
						<Form.Label>Confirm Password <span className="text-danger">*</span></Form.Label>
						<Form.Control type="password" onChange={(e) => setChangePassFormData({ ...ChangePassFormData, password_confirmation: e.target.value })}></Form.Control>
					</Form.Group>
					<div className="text-center pt-3">
						<Button  type="button" className="w-auto" onClick={handleChangePassword} variant="dark" >Change Password</Button>
					</div>
				</Modal.Body>
			</Modal>
        </>
    );
}

function UserEmailVerify(props){
    const [showEmailVerifiedModal, setShowEmailVerifiedModal] = useState(false);

    useEffect(()=>{
        let urlParams = new URLSearchParams(props.location.search);
        let verifyToken = urlParams.get('verifyToken');  
        let email = urlParams.get('email');  
        if( verifyToken !=null && email != null){  
            email=decodeURIComponent(email);
            AuthService.userEmailVerify(null,{
                token:verifyToken,
                email:email
            })
            .then((response) => {
                if(response.status == 200 && response.data.success){
                    
                    setShowEmailVerifiedModal(true);
                }
            })
            .catch((error)=>{
                console.log('error--',error);
            });
        }
    },[]);

    const handleEmailVerifiedModal = () => {
        setShowEmailVerifiedModal(false);
        props.history.push("/");
    }

    return (
        <Modal show={showEmailVerifiedModal} backdrop="static" keyboard={false}  onHide={handleEmailVerifiedModal} centered size="md" className="save-modal">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <i className="fa fa-check"></i>
                <h2> Your email is successfully verified.Now You can start referouting.</h2>
                <div className="text-right">
                    <Button onClick={handleEmailVerifiedModal} variant="dark">Ok</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
