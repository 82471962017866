/* ----------  Imports  ---------- */
// React
import React, { useState } from "react";

// Bootstrap
import {Form, Button, Modal } from "react-bootstrap";

// Services
import ProfileDataService from '../../../../services/individual/UserProfile';

/* ----------  Component  ---------- */

export default function PhoneVerificationModal({ value, countryCode, ...props }) {
	console.log('🚀 ~ PhoneVerificationModal ~ props:', props);
	const [phoneVerification, setPhoneVerification] = useState({
		otp: '',
		ref_id: ''
	});

	const [isSentOtp, setIsSentOtp] = useState(false);

	const handleSendOtp = () => {
		console.log('🚀 ~ PhoneVerificationModal ~ value, countryCode:', value, countryCode);
		/* ProfileDataService.sendPhoneVerificationOtp().then((res)=>{
			if(res.status === 200){
				setPhoneVerification({...phoneVerification,ref_id:res.data.data.ref_id})
				setIsSentOtp(true);
			}
		}).catch(error => {
			console.log(error);
		}); */
	}

	const handleOtpVerification = () =>{
		ProfileDataService.verifyPhoneOtp(phoneVerification).then((res)=>{
			console.log(res);
			if(res.data.success){
				setIsSentOtp(false);
				props.hideVerificationModal();
			}
		}).catch(error => {
			console.log(error);
		});
	}

	return (
		<Modal className="edit-modals" show={props.isShowModal} onHide={props.hideVerificationModal} centered size="md" backdrop="static" keyboard={false}>
			<Modal.Header closeButton>
				<Modal.Title>Mobile No. Verification</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{isSentOtp && (
					<>
						<Form.Group>
							<Form.Control
								type="text"
								name="phoneOtp"
								id="phoneOtp"
								onChange={e => setPhoneVerification({...phoneVerification,otp:e.target.value})}
							></Form.Control>
							<p>We have sent you OTP on {props.fullMobileNo}</p>
						</Form.Group>
						<div className="text-center pt-3">
							<Button className="btn btn-dark mr-2 px-5" onClick={handleOtpVerification}>
								Submit
							</Button>
						</div>
					</>
				)}
				{!isSentOtp && (
					<div className="text-center ">
						<p>Press send button to verify your Mobile no. with us.</p>
						<Button className="btn btn-dark mr-2 px-5" onClick={handleSendOtp}>
							Send
						</Button>
					</div>
				)}

			</Modal.Body>
		</Modal>
	);
}
